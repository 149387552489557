// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getAssetDetailsControlFlagsSelector from "./getAssetDetailsControlFlagsSelector";
import getAssetDetailsSelector from "./getAssetDetailsSelector";
import IAssetDetails from "../models/IAssetDetails";

const getAssetRiskSelector = createSelector(
  getAssetDetailsControlFlagsSelector,
  getAssetDetailsSelector,
  (assetDetailsControlFlagsSelector, assetDetailsSelector) => (
    assetId: string | undefined
  ) => {
    const { hasStatusSucceeded } = assetDetailsControlFlagsSelector(assetId);
    const assetDetails = assetDetailsSelector(assetId);
    return hasStatusSucceeded && assetDetails
      ? (assetDetails.data as IAssetDetails).Risk
      : undefined;
  }
);

export default getAssetRiskSelector;
