// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Layout from "core/components/Layout";
import useAppBar from "core/hooks/useAppBar";
import SearchParams from "core/models/SearchParams";
import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import Progress from "common/components/Progress";
import AssetDetailsAppBarContent from "./AssetDetailsAppBarContent";
import AssetDetailsNavigation from "./AssetDetailsNavigation";
import AssetDetailsSwitch from "./AssetDetailsSwitch";
import AssetNameplate from "./AssetNameplate";
import {
  loadAssetDetails,
  removeAssetDetails,
  loadAssetParameters,
  removeAssetParameters
} from "../actions/AssetDetailsActions";
import { loadActionPlan, removeActionPlan } from "../actions/ActionPlanActions";
import IAssetDetails from "../models/IAssetDetails";
import getAssetDetailsSelector from "../selectors/getAssetDetailsSelector";
import getAssetDetailsControlFlagsSelector from "../selectors/getAssetDetailsControlFlagsSelector";
import getAssetRiskSelector from "../selectors/getAssetRiskSelector";
import getAssetTypeSelector from "../selectors/getAssetTypeSelector";

import { spacingXXXXLarge } from "core/styles/StyleVariables";

const assetIdSearchParam = "assetId";

interface IAssetDetailsProps {
  className?: string;
}

const AssetDetails = ({ className }: IAssetDetailsProps) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { setAppBarContent, setAppBarCollapsibleContent } = useAppBar();

  const assetId = useMemo(() => {
    const searchParams = new SearchParams(location.search);
    return searchParams.get(assetIdSearchParam) || undefined;
  }, [location]);

  const assetDetailsControlFlagsSelector = useSelector(
    getAssetDetailsControlFlagsSelector
  );
  const {
    hasStatusLoading,
    hasStatusSucceeded,
    hasStatusFailed,
    hasStatusNotFound
  } = assetDetailsControlFlagsSelector(assetId);

  const assetDetailsSelector = useSelector(getAssetDetailsSelector);
  const assetDetails = useMemo(
    () => assetDetailsSelector(assetId),
    [assetDetailsSelector, assetId]
  );

  const assetRiskSelector = useSelector(getAssetRiskSelector);
  const risk = assetRiskSelector(assetId);

  const assetTypeSelector = useSelector(getAssetTypeSelector);
  const assetType = assetTypeSelector(assetId);

  useEffect(() => {
    setAppBarContent(() => (
      <AssetDetailsAppBarContent
        assetId={assetId}
        assetType={assetType}
        risk={risk}
      />
    ));
    setAppBarCollapsibleContent(() => <AssetNameplate assetId={assetId} />);
  }, [assetId, assetType, risk, setAppBarContent, setAppBarCollapsibleContent]);

  useEffect(() => {
    if (assetId) {
      dispatch(loadAssetDetails(assetId));
      dispatch(loadActionPlan(assetId));
      dispatch(loadAssetParameters(assetId));
    }
    return () => {
      if (assetId) {
        dispatch(removeAssetDetails(assetId));
        dispatch(removeActionPlan(assetId));
        dispatch(removeAssetParameters(assetId));
      }
    };
  }, [dispatch, assetId]);

  return (
    <>
      <Layout className={className} searchFabButtonBottom={spacingXXXXLarge}>
        {hasStatusLoading && <Progress />}
        {hasStatusSucceeded && assetDetails && (
          <div className="asset-details">
            <div className="asset-details-content">
              <AssetDetailsSwitch
                assetDetails={assetDetails.data as IAssetDetails}
                assetId={assetId}
              />
            </div>
            <AssetDetailsNavigation />
          </div>
        )}
        {hasStatusNotFound && <DataNotFoundMessage direction="column" />}
        {hasStatusFailed && <ApplicationErrorMessage direction="column" />}
      </Layout>
    </>
  );
};

const StyledAssetDetails = styled(AssetDetails)`
  height: 100%;

  .asset-details {
    height: 100%;
    display: flex;
    flex-direction: column;

    > .asset-details-content {
      flex-grow: 1;
      height: 0;
      overflow-y: auto;
      height: 100%;
    }
  }
`;

export default StyledAssetDetails;
