// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { Box, Container, Typography, IconButton } from "@material-ui/core";

import Icon from "common/components/Icon";

import useSearchQueryParam from "core/hooks/useSearchQueryParam";

import getSearchContextSelector from "core/selectors/getSearchContextSelector";

import { spacingNormal } from "core/styles/StyleVariables";
import {
  colorPrimary,
  colorFilterBarBackground
} from "core/styles/ColorVariables";
import useNavigate from "core/hooks/useNavigate";

interface IFilterQueryProps {
  className?: string;
}

const FilterQuery = ({ className }: IFilterQueryProps) => {
  const { searchQuery, removeSearchQuery } = useSearchQueryParam();

  const context = useSelector(getSearchContextSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const handleQueryClick = useCallback(() => {
    navigate({
      pathname: `/search/${encodeURIComponent(context)}`,
      search: location.search
    });
  }, [navigate, context, location.search]);

  return !isEmpty(searchQuery) && !isEmpty(context) ? (
    <Box className={`${className} filter-query`} onClick={handleQueryClick}>
      <Container className="filter-query-container">
        <Box className="query">
          <Icon
            name="search"
            size="sm"
            variant="outlined"
            dataQa="search-bar-icon"
          />
          <Typography>{searchQuery}</Typography>
        </Box>
        <Box
          className="remove-search"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            removeSearchQuery();
          }}
        >
          <IconButton>
            <Icon name="close" variant="outlined" dataQa="reset-filter" />
          </IconButton>
        </Box>
      </Container>
    </Box>
  ) : null;
};

const StyledFilterQuery = styled(FilterQuery)`
  display: flex;
  align-items: center;
  position: static;
  bottom: 0;
  width: 100%;
  background-color: ${colorFilterBarBackground};
  color: ${colorPrimary};
  box-sizing: border-box;
  border-top: 1px solid ${colorPrimary};
  border-bottom: 1px solid ${colorPrimary};

  .filter-query-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: ${spacingNormal};
    padding-bottom: ${spacingNormal};
  }

  .remove-search {
    width: 10%;
    text-align: right;
  }

  .query {
    display: flex;
    align-items: center;
    width: 90%;
    text-align: left;

    .MuiTypography-root {
      max-width: 78vw;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .icon {
      padding-right: ${spacingNormal};
    }
  }

  .icon {
    color: ${colorPrimary};
  }

  .MuiTypography-root {
    font-weight: bold;
  }

  .MuiButtonBase-root {
    color: ${colorPrimary};
    padding: 0;
  }
`;

export default StyledFilterQuery;
