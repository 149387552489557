// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { dataKey } from "../actions/EnvironmentActions";
import IEnvironment from "../models/IEnvironment";

const getTenantsSelector = createSelector(
  (state: IRootState) => state.data[dataKey],
  (environment: ApiResponse<IEnvironment>) => environment
);

export default getTenantsSelector;
