// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IRootState } from "reducers";

const getMoreIconVisibleSelector = createSelector(
  (state: IRootState) => state.ui.appBar.moreIconVisible,
  moreIconVisible => moreIconVisible
);

export default getMoreIconVisibleSelector;
