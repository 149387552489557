// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getLicensesDataKey } from "../actions/LicensesActions";
import ICommonLicense from "../models/ICommonLicense";

const getCommonLicensesSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => data[getLicensesDataKey("common")] as ApiResponse<ICommonLicense[]>
);

export default getCommonLicensesSelector;
