// Copyright 2016-2024 Hitachi Energy. All rights reserved.

export class ApiUrgencies {
  static readonly AsSoonAsPossible = "AsSoonAsPossible";
  static readonly WithinAYear = "WithinAYear";
  static readonly WithinOneToThreeYears = "WithinOneToThreeYears";
  static readonly WithinThreeToFiveYears = "WithinThreeToFiveYears";
  static readonly Unknown = "Unknown";
}

export class UiUrgencies {
  static readonly AsSoonAsPossible = "AsSoonAsPossible";
  static readonly Normal = "Normal";
  static readonly LongTerm = "LongTerm";
  static readonly Unknown = "Unknown";
}

export default class UrgencyConverter {
  static convertApiUrgencyToUiUrgency(urgencyLevel: string): string {
    switch (urgencyLevel) {
      case ApiUrgencies.AsSoonAsPossible:
      case ApiUrgencies.WithinAYear:
        return UiUrgencies.AsSoonAsPossible;
      case ApiUrgencies.WithinOneToThreeYears:
        return UiUrgencies.Normal;
      case ApiUrgencies.WithinThreeToFiveYears:
        return UiUrgencies.LongTerm;
      case ApiUrgencies.Unknown:
      default:
        return UiUrgencies.Unknown;
    }
  }
}
