// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback, useLayoutEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Typography } from "@material-ui/core";
import Icon from "common/components/Icon";

import useFeatureToggles from "common/hooks/useFeatureToggles";
import useDrawer from "core/hooks/useDrawer";
import getCurrentTenantSelector from "core/selectors/getCurrentTenantSelector";
import getTenantsSelector from "core/selectors/getTenantsSelector";

import {
  TenantSwitchHeader,
  TenantSwitchContent,
  TenantSwitchFooter
} from "./TenantSwitch";

interface ITenantProps {
  className?: string;
}

const Tenant = ({ className }: ITenantProps) => {
  const { closeDrawer } = useDrawer("right");
  const { openDrawer, setDrawerHeader, setDrawerContent, setDrawerFooter } =
    useDrawer("bottom");
  const currentTenant = useSelector(getCurrentTenantSelector);
  const { showTenantSwitch } = useFeatureToggles();
  const tenants = useSelector(getTenantsSelector);
  const allowToSwitch = useMemo(
    () =>
      showTenantSwitch || (tenants.data?.length && tenants.data?.length > 1),
    [showTenantSwitch, tenants.data]
  );

  const handleClick = useCallback(() => {
    closeDrawer();
    openDrawer();
  }, [closeDrawer, openDrawer]);

  useLayoutEffect(() => {
    setDrawerHeader(() => <TenantSwitchHeader />);
    setDrawerContent(({ state, setState }) => (
      <TenantSwitchContent
        selectedTenant={state}
        setSelectedTenant={setState}
      />
    ));
    setDrawerFooter(({ state, setState }) => (
      <TenantSwitchFooter selectedTenant={state} />
    ));
  }, [setDrawerHeader, setDrawerContent, setDrawerFooter]);

  return (
    <div
      className={className}
      data-qa="tenant"
      onClick={allowToSwitch ? handleClick : undefined}
    >
      <Typography className="name" variant="body1">
        {currentTenant?.DisplayName}
      </Typography>
      {allowToSwitch && <Icon name="settings" />}
    </div>
  );
};

export default styled(Tenant)`
  display: flex;
  align-items: center;

  .name {
    word-break: break-word;
  }

  .icon {
    margin-left: 1ch;
  }
`;
