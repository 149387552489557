// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import { isNil } from "lodash";

import { Statuses } from "@apm/endpoint";

import getTranslationSelector from "./getTranslationsSelector";
import LocaleService from "../services/LocaleService";

const getLocaleSelector = createSelector(
  getTranslationSelector,
  (translationResponse) => {
    if (
      translationResponse.status === Statuses.Succeeded &&
      !isNil(translationResponse.data)
    ) {
      return findFirstMatchingLocale(translationResponse.data.locales);
    }

    return "en";
  }
);

export default getLocaleSelector;

function findFirstMatchingLocale(locales: string[]) {
  const browserLocales = LocaleService.getBrowserLocales();

  if (locales) {
    for (let i = 0; i < browserLocales.length; i++) {
      if (locales.indexOf(browserLocales[i]) !== -1) {
        return browserLocales[i];
      }
    }
  }

  return "en";
}
