// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useState, useLayoutEffect } from "react";
import { debounce } from "lodash";

import isIOS from "utils/isIOS";

const useWindowSize = () => {
  let [size, setSize] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    };
    let resizeHandler = updateSize;

    // Apple vs. Developer - 1 : 0
    // https://hackernoon.com/onresize-event-broken-in-mobile-safari-d8469027bf4d
    if (isIOS()) {
      resizeHandler = debounce(updateSize, 500);
    }

    window.addEventListener("resize", resizeHandler);
    updateSize();
    return () => window.removeEventListener("resize", resizeHandler);
  }, []);
  return size;
};

export default useWindowSize;
