// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import ILicenseData from "../models/ILicenseData";
import Grid from "common/components/Grid";
import LicensesListItem from "./LicensesListItem";

interface ILicensesListProps {
  data: ILicenseData[];
}

const LicensesList = ({ data }: ILicensesListProps) => {
  return (
    <Grid
      className="list-of-licenses"
      dataOrConfig={data}
      ItemComponent={LicensesListItem}
    />
  );
};

export default LicensesList;
