// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { Box, Typography } from "@material-ui/core";
import styled from "styled-components";

import DateString from "common/formatters/DateString";

interface IIssueDetailsAppBarProps {
  className?: string;
  issueName: string | null | undefined;
  date: string | null | undefined;
}

const IssueDetailsAppBar = ({
  className,
  issueName,
  date
}: IIssueDetailsAppBarProps) => {
  return (
    <Box className={className} display="flex" flexDirection="column">
      <Typography variant="h6" className="issue-name">
        {issueName}
      </Typography>

      {date && <DateString value={date} variant="body2" showDateWithoutTime />}
    </Box>
  );
};

const StyledIssueDetailsAppBar = styled(IssueDetailsAppBar)`
  h6 {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default StyledIssueDetailsAppBar;
