// Copyright 2016-2024 Hitachi Energy. All rights reserved.

export interface ISearchParams {
  name: string;
  value: string;
}

export default class SearchParams {
  private params: URLSearchParams;

  constructor(init?: string | Record<string, string> | URLSearchParams) {
    this.params = new URLSearchParams(init);
  }

  append(name: string, value: string): void {
    this.params.append(name, value);
  }

  delete(name: string): void {
    this.params.delete(name);
  }

  get(name: string): string | null {
    return this.params.get(name);
  }

  getAll(name: string): string[] {
    return this.params.getAll(name);
  }

  has(name: string): boolean {
    return this.params.has(name);
  }

  set(name: string, value: string): void {
    this.params.set(name, value);
  }

  settle(name: string, value: string | undefined): void {
    if (value !== undefined) {
      this.params.set(name, value);
    } else if (this.params.has(name)) {
      this.params.delete(name);
    }
  }

  sort(): void {
    (this.params as any).toString();
  }

  forEach(
    callback: (value: string, key: string, parent: URLSearchParams) => void,
    thisArg?: any
  ): void {
    (this.params as any).forEach(callback, thisArg);
  }

  toString(): string {
    return this.params.toString();
  }

  toArray(): ISearchParams[] {
    const params: ISearchParams[] = [];
    (this.params as any).forEach((value: string, key: string) =>
      params.push({
        name: key,
        value: value
      })
    );

    return params;
  }

  toObject(): { [name: string]: string } {
    const params: { [name: string]: string } = {};
    (this.params as any).forEach((value: string, key: string) => {
      params[key] = value;
    });
    return params;
  }
}
