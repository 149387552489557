// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback, FunctionComponent } from "react";
import { useDispatch } from "react-redux";

import { IDrawerComponentProps } from "core/reducers/DrawerReducer";

import {
  openDrawerAction,
  closeDrawerAction,
  toggleDrawerAction,
  setDrawerContentAction,
  setDrawerHeaderAction,
  setDrawerFooterAction
} from "../actions/DrawerActions";
import DrawerTypes from "../models/DrawerTypes";

const useDrawer = (drawerType: DrawerTypes) => {
  const dispatch = useDispatch();

  const openDrawer = useCallback(() => dispatch(openDrawerAction(drawerType)), [
    dispatch,
    drawerType
  ]);

  const closeDrawer = useCallback(
    () => dispatch(closeDrawerAction(drawerType)),
    [dispatch, drawerType]
  );

  const toggleDrawer = useCallback(
    () => dispatch(toggleDrawerAction(drawerType)),
    [dispatch, drawerType]
  );

  const setDrawerContent = useCallback(
    (Content: FunctionComponent<IDrawerComponentProps>) =>
      dispatch(setDrawerContentAction(drawerType, Content)),
    [dispatch, drawerType]
  );

  const setDrawerHeader = useCallback(
    (Header: FunctionComponent<IDrawerComponentProps>) =>
      dispatch(setDrawerHeaderAction(drawerType, Header)),
    [dispatch, drawerType]
  );

  const setDrawerFooter = useCallback(
    (Footer: FunctionComponent<IDrawerComponentProps>) =>
      dispatch(setDrawerFooterAction(drawerType, Footer)),
    [dispatch, drawerType]
  );

  return {
    openDrawer,
    closeDrawer,
    toggleDrawer,
    setDrawerContent,
    setDrawerHeader,
    setDrawerFooter
  };
};

export default useDrawer;
