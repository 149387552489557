// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Action } from "redux";
import IRecentSearch from "../models/IRecentSearch";

export enum Actions {
  Search_AddRecentSearch = "Search_AddRecentSearch"
}

export interface IAddRecentSearchAction extends Action<string> {
  recentSearch: IRecentSearch;
}

export const addRecentSearchAction = (
  recentSearch: IRecentSearch
): IAddRecentSearchAction => ({
  type: Actions.Search_AddRecentSearch,
  recentSearch
});
