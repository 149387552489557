// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";

const getUnit = (intl: IntlShape, parameterId: string) => {
  const hasUnit = ["RatedPower", "Voltage"].indexOf(parameterId) >= 0;
  return hasUnit
    ? intl.formatMessage({
        defaultMessage: "unknown",
        id: `mobile.asset_details.parameter.units.${parameterId}`
      })
    : null;
};

export default getUnit;
