// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Button, Container, Grid } from "@material-ui/core";
import Icon from "common/components/Icon";

import useDrawer from "core/hooks/useDrawer";
import useNavigate from "core/hooks/useNavigate";

interface IHomepageNavigationProps {
  className?: string;
}

const HomepageNavigation = ({ className }: IHomepageNavigationProps) => {
  const { closeDrawer } = useDrawer("right");
  const navigate = useNavigate();
  const handleClick = () => {
    closeDrawer();
    navigate("/");
  };

  return (
    <Container className={className}>
      <Grid className="button-wrapper" container spacing={4}>
        <Grid item>
          <Button
            className="btn-contained-dark"
            onClick={handleClick}
            startIcon={<Icon name="home" />}
            variant="contained"
          >
            <FormattedMessage
              defaultMessage="Homepage"
              id="mobile.sidepanel.homepage"
            />
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default styled(HomepageNavigation)`
  padding: 1em;

  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
  }
`;
