// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createGlobalStyle } from "styled-components";

import {
  colorAppBackgroundLight,
  colorGray30,
  colorGray110,
  colorTextPrimaryDark
} from "core/styles/ColorVariables";
import { fontSizeSmall, fontSizeNormal } from "core/styles/StyleVariables";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${colorAppBackgroundLight};
    overflow-y: hidden;
  }

  button.btn-outlined-dark {
    border-color: ${colorTextPrimaryDark};
    color: ${colorTextPrimaryDark};
  }

  button.btn-contained-dark {
    background-color: ${colorTextPrimaryDark};
  }

  #root {
    height: 100%;
  }

  .MuiBottomNavigation-root {
    .MuiBottomNavigationAction-root {
      .MuiBottomNavigationAction-wrapper {
        color: ${colorGray30};

        .icon {
          font-size: ${fontSizeNormal};
        }

        .MuiBottomNavigationAction-label {
          font-weight: bold;
          text-transform: uppercase;
        }
      }
    }

    .MuiBottomNavigationAction-root.Mui-selected {
      .MuiBottomNavigationAction-wrapper {
        color: ${colorGray110};

        .MuiBottomNavigationAction-label {
         font-size: ${fontSizeSmall}
        }
      }
    }
  }

  .MuiButton-outlined,
  .MuiButton-outlined.Mui-disabled {
    border-width: 2px;
  }
`;

export default GlobalStyle;
