// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { FunctionComponent } from "react";
import { Action } from "redux";

import DrawerTypes from "core/models/DrawerTypes";

import {
  Actions,
  IOpenDrawerAction,
  ICloseDrawerAction,
  IToggleDrawerAction,
  ISetDrawerContentAction,
  ISetDrawerHeaderAction,
  ISetDrawerFooterAction
} from "../actions/DrawerActions";

export interface IDrawerComponentProps {
  state: any;
  setState: (state: any) => void;
}

export interface IDrawerState {
  visible: boolean;
  Header?: FunctionComponent<IDrawerComponentProps>;
  Content?: FunctionComponent<IDrawerComponentProps>;
  Footer?: FunctionComponent<IDrawerComponentProps>;
}

export interface IState {
  drawers: {
    [name: string]: IDrawerState;
  };
}

const defaultDrawerState: IDrawerState = {
  visible: false
};

const updateDrawer = (
  state: IState,
  drawerType: DrawerTypes,
  drawerState: Partial<IDrawerState>
): IState => {
  const newState = { ...state };
  newState.drawers = { ...newState.drawers };
  newState.drawers[drawerType] = {
    ...(newState.drawers[drawerType] || defaultDrawerState),
    ...drawerState
  };
  return newState;
};

const reducer = (state: IState, action: Action<string>) => {
  switch (action.type) {
    case Actions.Drawer_OpenDrawer: {
      const { drawerType } = action as IOpenDrawerAction;
      return updateDrawer(state, drawerType, {
        visible: true
      });
    }
    case Actions.Drawer_CloseDrawer: {
      const { drawerType } = action as ICloseDrawerAction;
      return updateDrawer(state, drawerType, {
        visible: false
      });
    }
    case Actions.Drawer_ToggleDrawer: {
      const { drawerType } = action as IToggleDrawerAction;
      return updateDrawer(state, drawerType, {
        visible: !state.drawers[drawerType].visible
      });
    }
    case Actions.Drawer_SetContent: {
      const { drawerType, Content } = action as ISetDrawerContentAction;
      return updateDrawer(state, drawerType, {
        Content
      });
    }
    case Actions.Drawer_SetHeader: {
      const { drawerType, Header } = action as ISetDrawerHeaderAction;
      return updateDrawer(state, drawerType, {
        Header
      });
    }
    case Actions.Drawer_SetFooter: {
      const { drawerType, Footer } = action as ISetDrawerFooterAction;
      return updateDrawer(state, drawerType, {
        Footer
      });
    }
    default:
      return state;
  }
};

export default reducer;
