// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import IssueCondition from "./IssueCondition";
import IssueRecommendation from "./IssueRecommendation";
import IssueUrgency from "./IssueUrgency";
import IssueMaintenance from "./IssueMaintenance";
import IIssueDetails from "../models/IIssueDetails";

import { spacingXXXLarge, spacingXLarge } from "core/styles/StyleVariables";

interface IIssueOverviewProps {
  className?: string;
  issueData: IIssueDetails;
}

const IssueOverview = ({
  className,
  issueData: {
    ConditionText,
    RecommendationText,
    Urgency,
    MaintenancePriorityScoreOrder,
    TotalNumberOfIssues,
    Status
  }
}: IIssueOverviewProps) => {
  return (
    <div className={`issue-overview ${className}`} data-qa="issue-overview">
      <IssueCondition condition={ConditionText} />
      <IssueRecommendation recommendation={RecommendationText} />
      <IssueUrgency urgency={Urgency}></IssueUrgency>
      {Status !== "Closed" && Status !== "Monitor" && <IssueMaintenance
        maintenance={MaintenancePriorityScoreOrder}
        totalNumber={TotalNumberOfIssues}
      />}
    </div>
  );
};

const StyledIssueOverview = styled(IssueOverview)`
  padding-left: ${spacingXXXLarge};
  padding-right: ${spacingXXXLarge};
  padding-top: ${spacingXLarge};
  padding-bottom: ${spacingXLarge};
`;

export default StyledIssueOverview;
