// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Routes, Route } from "react-router-dom";

import AssetOverview from "./AssetOverview";
import AssetParameters from "./AssetParameters";
import ActionPlan from "./ActionPlan";
import IAssetDetails from "../models/IAssetDetails";
import IModelDetails from "common/models/IModelDetails";

interface IAssetDetailsSwitchProps {
  assetDetails: IAssetDetails;
  assetId: string | undefined;
}

const AssetDetailsSwitch = ({
  assetDetails,
  assetId
}: IAssetDetailsSwitchProps) => {
  const model: IModelDetails | null = assetDetails.Nameplate
    ? {
        modelId: assetDetails.Nameplate.ModelId || "",
        implementationId: assetDetails.Nameplate.ModelImplementationId || ""
      }
    : null;

  return (
    <Routes>
      <Route index element={<AssetOverview assetDetails={assetDetails} />} />
      <Route
        path="parameters"
        element={<AssetParameters assetId={assetId} model={model} />}
      />
      <Route path="action-plan" element={<ActionPlan assetId={assetId} />} />
    </Routes>
  );
};

export default AssetDetailsSwitch;
