// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { spacingNormal } from "core/styles/StyleVariables";
import IAssetDetails from "features/assetdetails/models/IAssetDetails";
import DeviceInfo from "./DeviceInfo";
import RiskDetails from "./RiskDetails";

interface IAssetOverviewProps {
  assetDetails: IAssetDetails;
  className?: string;
}

const AssetOverview = ({ assetDetails, className }: IAssetOverviewProps) => {
  return (
    <div className={`asset-overview-content ${className}`}>
      <DeviceInfo assetDetails={assetDetails} />
      <RiskDetails assetDetails={assetDetails} />
    </div>
  );
};

const StyledAssetOverview = styled(AssetOverview)`
  padding: ${spacingNormal};

  .risk-details {
    margin-top: ${spacingNormal};
  }
`;

export default StyledAssetOverview;
