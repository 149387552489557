// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";

const timeoutMs = 20 * 1000;

const getNavigatorGeolocationPosition = (options?: PositionOptions) =>
  new Promise<GeolocationPosition | undefined>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve(position);
      },
      (positionError) => {
        reject(positionError);
      },
      options
    );
  });

const timeoutGeolocationPosition = (): Promise<undefined> =>
  new Promise((reject) => setTimeout(reject, timeoutMs));

const useGeolocation = () => {
  const getCurrentPosition = useCallback(
    (options?: PositionOptions) =>
      Promise.race<GeolocationPosition | undefined>([
        getNavigatorGeolocationPosition(options),
        timeoutGeolocationPosition()
      ]),
    []
  );

  return getCurrentPosition;
};

export default useGeolocation;
