// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import ITenant from "core/models/ITenant";

import SearchParams from "core/models/SearchParams";
import endpoint from "@apm/endpoint";

const customerHeaderName = "X-CustomerInternalName";

export const tenantSearchParamName = "tenant";

export default class TenantService {
  static setupTenant(
    userTenant: ITenant | undefined,
    tenants: ITenant[],
    switchTenant: (tenant: ITenant | undefined) => void
  ) {
    return new Promise<void>((resolve) => {
      const tenantNameParam = this.getTenantFromSearch();

      const applySearchParams = () =>
        new Promise<void>((resolve) => {
          const tenantParam = this.getTenantByName(tenantNameParam, tenants);

          const switchIsNotNeeded =
            tenantNameParam === userTenant?.InternalName;

          if (!tenantParam) {
            resolve();
          } else if (tenantParam && switchIsNotNeeded) {
            resolve();
          } else {
            switchTenant(tenantParam);
          }
        });

      applySearchParams().then(() => {
        const matchedTenant = this.getTenantByName(
          userTenant?.InternalName,
          tenants
        );

        if (userTenant === undefined) {
          this.configureInterceptor(userTenant);
          resolve();
        } else if (matchedTenant) {
          this.configureInterceptor(matchedTenant);
          resolve();
        } else {
          switchTenant(undefined);
        }
      });
    });
  }

  private static configureInterceptor(userTenant: ITenant | undefined) {
    const customerHeaderValue = userTenant?.InternalName;

    if (this.interceptorId !== undefined) {
      endpoint.interceptors.request.eject(this.interceptorId);
      this.interceptorId = undefined;
    }

    if (customerHeaderValue) {
      this.interceptorId = endpoint.interceptors.request.use((request) => {
        if (request.headers)
          request.headers[customerHeaderName] = customerHeaderValue;
        return request;
      });
    }
  }

  private static getTenantByName(
    tenantName: string | undefined,
    tenants: ITenant[]
  ) {
    if (tenantName === undefined) return undefined;
    const i = tenants.findIndex((t) => t.InternalName === tenantName);
    return i >= 0 ? tenants[i] : undefined;
  }

  private static getTenantFromSearch() {
    const searchParams = new SearchParams(window.location.search);
    const tenant = searchParams.get(tenantSearchParamName);
    return tenant !== null ? tenant : undefined;
  }

  private static interceptorId: number | undefined;
}
