// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import * as React from "react";
import { useIntl, IntlShape } from "react-intl";
import IModelDetails from "common/models/IModelDetails";
import ModelMessageService from "./ModelMessage";

export interface ISubscoreName {
  nameId: string;
  model: IModelDetails | null;
}

const SubscoreName = ({ nameId, model }: ISubscoreName) => {
  const intl = useIntl();
  const formattedValue = SubscoreService.format(nameId, model, intl);
  return <span>{formattedValue}</span>;
};

export default SubscoreName;

export class SubscoreService {
  private static readonly subscoreIntlPrefix = "subscore";

  static format(
    nameId: string,
    model: IModelDetails | null,
    intl: IntlShape
  ): string {
    const subscoreId = nameId.trim();

    return ModelMessageService.format(
      intl,
      model,
      this.subscoreIntlPrefix,
      subscoreId
    );
  }
}
