// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";

import { Typography } from "@material-ui/core";

interface IOrganizationRegionProps {
  organization: string | null;
  region: string | null;
}

const OrganizationRegion = ({
  organization,
  region
}: IOrganizationRegionProps) => (
  <Typography variant="body2" className="organization-region-name">
    {organization && region ? (
      <FormattedMessage
        defaultMessage="{organization} / {region}"
        id="mobile.location.organization_region"
        values={{
          organization,
          region
        }}
      />
    ) : !region ? (
      organization
    ) : !organization ? (
      region
    ) : null}
  </Typography>
);

export default OrganizationRegion;
