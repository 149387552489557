// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import endpoint, { getUrl, getApiUrl } from "@apm/endpoint";

import {
  loadDataAction,
  removeResponseAction
} from "../../../core/actions/DataActions";
import LicenseType from "../models/LicenseType";

const commonLicensesUrl = "licenses/common";
const npmLicensesUrl = "/static/licenses/licenses.npm.json";
const nugetLicensesUrl = "licenses/nuget";
const mavenLicensesUrl = "licenses/maven";
const nugetLicenseTextUrl = "licenses/{licenseType}/text/{fileName}";

const licensesDataKey = "licenses";
const licenseTextDataKey = "licenseText";

export const getLicensesDataKey = (name: string) =>
  `${licensesDataKey}-${name}`;

export const getLicenseTextDataKey = (
  licenseType: LicenseType,
  fileName: string
) => `${licenseTextDataKey}-${licenseType}-${fileName}`;

export const loadLicenses = (licenseType: LicenseType) => {
  const url =
    licenseType === "common"
      ? getApiUrl(commonLicensesUrl)
      : licenseType === "npm"
      ? getUrl(npmLicensesUrl)
      : licenseType === "nuget"
      ? getApiUrl(nugetLicensesUrl)
      : licenseType === "maven"
      ? getApiUrl(mavenLicensesUrl)
      : "";
  return loadDataAction(getLicensesDataKey(licenseType), endpoint.get, url);
};

export const loadNugetLicenseText = (
  licenseType: LicenseType,
  fileName: string
) => {
  const url = getApiUrl(nugetLicenseTextUrl, [
    { name: "licenseType", value: licenseType },
    { name: "fileName", value: fileName }
  ]);
  return loadDataAction(
    getLicenseTextDataKey(licenseType, fileName),
    endpoint.get,
    url
  );
};

export const removeLicenses = (licenseType: LicenseType) =>
  removeResponseAction(getLicensesDataKey(licenseType));

export const removeLicenseText = (licenseType: LicenseType, fileName: string) =>
  removeResponseAction(getLicenseTextDataKey(licenseType, fileName));
