// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Action } from "redux";

import endpoint, { getApiUrl } from "@apm/endpoint";

import { loadDataAction } from "core/actions/DataActions";
import ITenant from "core/models/ITenant";

const tenantsUrl = "user/tenantslist";

export const dataKey = "tenants";

export enum Actions {
  App_SetUserTenant = "App_SetUserTenant",
}

export interface ISetUserTenantAction extends Action<string> {
  tenant: ITenant | undefined;
}

export const setUserTenantAction = (
  tenant: ITenant | undefined
): ISetUserTenantAction => ({
  type: Actions.App_SetUserTenant,
  tenant,
});

export const loadTenantsAction = () => {
  const url = getApiUrl(tenantsUrl);
  return loadDataAction(dataKey, endpoint.get, url);
};
