// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Reducer } from "redux";
import { Actions, IAddRecentSearchAction } from "../actions/SearchActions";

import IRecentSearch from "../models/IRecentSearch";

export interface IState {
  recentSearches: Array<IRecentSearch>;
}

export const defaultState: IState = {
  recentSearches: []
};

const reducer: Reducer<IState> = (
  state: IState = defaultState,
  action: any
) => {
  switch (action.type) {
    case Actions.Search_AddRecentSearch:
      const { recentSearch } = action as IAddRecentSearchAction;
      const clonedArray = [...state.recentSearches];
      clonedArray.unshift(recentSearch);

      return {
        ...state,
        recentSearches: clonedArray
      };
    default:
      return state;
  }
};

export default reducer;
