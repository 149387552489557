// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { IMessagesProps } from "../Message";

import { colorGray100 } from "core/styles/ColorVariables";

const ApplicationErrorMessageMessages = styled(
  ({ className }: IMessagesProps) => (
    <Typography className={className} variant="body1">
      <FormattedMessage
        defaultMessage="Application error"
        id="mobile.application_error_message"
      />
    </Typography>
  )
)`
  color: ${colorGray100};
`;

export default ApplicationErrorMessageMessages;
