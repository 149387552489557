// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import ITenant from "core/models/ITenant";
import getCurrentTenantSelector from "core/selectors/getCurrentTenantSelector";
import getTenantsSelector from "core/selectors/getTenantsSelector";

import { ITenantGridItemData } from "../components/TenantSwitch/TenantGridItem";

const useTenantSwitchContent = (
  selectedTenant: ITenant,
  setSelectedTenant: (tenant: ITenant) => void
) => {
  const tenants = useSelector(getTenantsSelector);
  const currentTenant = useSelector(getCurrentTenantSelector);

  const gridData = useMemo(
    () =>
      (tenants.data || []).map<ITenantGridItemData>(t => {
        const selected = selectedTenant
          ? selectedTenant.InternalName === t.InternalName
          : currentTenant?.InternalName === t.InternalName;

        return {
          selected,
          tenant: t
        };
      }),
    [currentTenant, selectedTenant, tenants.data]
  );

  const handleItemClick = useCallback(
    (data: ITenantGridItemData) => {
      setSelectedTenant(data.tenant);
    },
    [setSelectedTenant]
  );

  return { gridData, handleItemClick };
};

export default useTenantSwitchContent;
