// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getMavenLicensesSelector from "./getMavenLicensesSelector";

const getMavenLicensesStatusSelector = createSelector(
  getMavenLicensesSelector,
  (mavenLicensesSelector) => () => {
    const mavenLicenses = mavenLicensesSelector;
    return mavenLicenses && mavenLicenses.status;
  }
);

export default getMavenLicensesStatusSelector;
