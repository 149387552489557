// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { dataKey as userDataKey } from "core/actions/UserActions";
import { dataKey as tenantsDataKey } from "core/actions/TenantsActions";
import IUser from "core/models/IUser";
import { IRootState } from "reducers";

import ITenant from "../models/ITenant";

const getCurrentTenantSelector = createSelector(
  (state: IRootState) => state.app.userTenant,
  (state: IRootState) => state.data[userDataKey],
  (state: IRootState) => state.data[tenantsDataKey],
  (
    userTenant: ITenant | undefined,
    user: ApiResponse<IUser>,
    tenants: ApiResponse<ITenant[]>
  ) => {
    const matchedTenants = tenants.data?.filter(
      (t) => t.DisplayName === user.data?.CustomerId
    );

    const defaultTenant =
      matchedTenants && matchedTenants.length > 0
        ? matchedTenants[0]
        : undefined;

    return userTenant || defaultTenant;
  }
);

export default getCurrentTenantSelector;
