// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import IDataClient from "./IDataClient";
import { Statuses } from "../models/Statuses";

export default class LocalClient implements IDataClient {
  private data: Array<any> | undefined;
  private destroyed: boolean;

  constructor(data: Array<any> | undefined) {
    this.data = data;
    this.destroyed = false;
  }

  loadFirst(
    chunkSize: number,
    setData: (data: Array<any>) => void,
    setTotal: (total: number) => void,
    setStatus: (status: Statuses) => void
  ): Promise<unknown> {
    this.destroyed = false;
    setStatus(Statuses.Loading);
    return new Promise<void>((resolve) => {
      const dataChunk = this.getDataChunk(chunkSize);

      if (!this.destroyed) {
        setData(dataChunk);
        setTotal(this.getTotal());
        setStatus(Statuses.Succeeded);
      }

      resolve();
    });
  }

  loadNext(
    currentData: Array<any>,
    chunkSize: number,
    setData: (data: Array<any>) => void,
    setTotal: (total: number) => void,
    setStatus: (status: Statuses) => void
  ): Promise<unknown> {
    this.destroyed = false;
    setStatus(Statuses.Updating);
    return new Promise<void>((resolve) => {
      const dataChunk = this.getDataChunk(chunkSize, currentData.length);
      const newData = LocalClient.concatData(currentData, dataChunk);

      if (!this.destroyed) {
        setData(newData);
        setTotal(this.getTotal());
        setStatus(Statuses.Succeeded);
      }

      resolve();
    });
  }

  destroy() {
    this.destroyed = true;
  }

  canLoadNext(currentCount: number, status: Statuses | undefined): boolean {
    const total = this.getTotal();
    if (total === 0) return false;
    if (currentCount >= total) return false;
    if (status !== Statuses.Succeeded) return false;
    return true;
  }
  private getDataChunk(chunkSize: number, start: number = 0) {
    if (this.data === undefined) return [];

    const end = Math.min(start + chunkSize, this.data.length);
    const chunk = this.data.slice(start, end);
    return chunk;
  }

  private getTotal() {
    return this.data ? this.data.length : 0;
  }

  private static concatData(data1: Array<any>, data2: Array<any>) {
    return data1.concat(data2);
  }
}
