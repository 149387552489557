// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useRef, useEffect } from "react";

const useIsMounted = () => {
  const mountedRef = useRef(false);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return () => mountedRef.current;
};

export default useIsMounted;
