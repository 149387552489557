// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getDataKey } from "../actions/ActionPlanActions";
import IIssue from "../models/IIssue";

const getActionPlanSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => (assetId: string | undefined) =>
    assetId ? (data[getDataKey(assetId)] as ApiResponse<IIssue[]>) : undefined
);

export default getActionPlanSelector;
