// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Reducer, Action } from "redux";
import drawerReducer, { IState as IDrawerState } from "./DrawerReducer";
import appBarReducer, { IState as IAppBarReducer } from "./AppBarReducer";
import searchReducer, {
  IState as ISearchReducer,
  SearchActionsType
} from "./SearchReducer";

export interface IState extends IDrawerState, IAppBarReducer, ISearchReducer {}

export const defaultState: IState = {
  appBar: {
    backArrowVisible: true,
    moreIconVisible: true
  },
  drawers: {},
  search: {
    context: ""
  }
};

const reducer: Reducer<IState> = (
  state: IState = defaultState,
  action: Action<string> | SearchActionsType
) => {
  state = drawerReducer(state, action) as IState;
  state = appBarReducer(state, action) as IState;
  state = searchReducer(state, action as SearchActionsType) as IState;
  return state;
};

export default reducer;
