// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Box, Fab } from "@material-ui/core";

import Icon from "common/components/Icon";
import SearchParams from "core/models/SearchParams";
import {
  colorAppBackgroundDark,
  colorTextPrimaryDark
} from "core/styles/ColorVariables";
import useNavigate from "core/hooks/useNavigate";

interface ISearchButtonProps {
  className?: string;
  context: string;
}

const SearchFabButton = ({ className, context }: ISearchButtonProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new SearchParams(location.search);
  if (searchParams.has("search")) searchParams.delete("search");

  const handleFabClick = () => {
    navigate({
      pathname: `/search/${encodeURIComponent(context)}`,
      search: searchParams.toString()
    });
  };

  return (
    <Box className={`search ${className}`}>
      <Fab aria-label="search" data-qa="search-button" onClick={handleFabClick}>
        <Icon name="search" size="lg" />
      </Fab>
    </Box>
  );
};

const StyledSearchFabButton = styled(SearchFabButton)`
  .MuiFab-root {
    background-color: ${colorAppBackgroundDark};
    color: ${colorTextPrimaryDark};
  }

  .MuiFab-root:hover {
    background-color: ${colorAppBackgroundDark};
  }
`;

export default StyledSearchFabButton;
