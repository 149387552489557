// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsTransmissionLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.83 17a3.001 3.001 0 110-2H8v-2h16v2h2.17a3.001 3.001 0 110 2H24v2H8v-2H5.83z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsTransmissionLine;
