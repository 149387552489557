// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";
import LicenseType from "../models/LicenseType";

import getLicenseTextSelector from "./getLicenseTextSelector";

const getLicenseTextStatusSelector = createSelector(
  getLicenseTextSelector,
  (licenseTextSelector) => (licenseType: LicenseType, fileName: string) => {
    const licenseText = licenseTextSelector(licenseType, fileName);
    return licenseText && licenseText.status;
  }
);

export default getLicenseTextStatusSelector;
