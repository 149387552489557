// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { isEmpty, some } from "lodash";
import React from "react";
import styled from "styled-components";

import { Statuses } from "@apm/endpoint";

import Grid from "common/components/Grid";
import Progress from "common/components/Progress";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import ActionPlanItem from "./ActionPlanItem";
import useActionPlan from "../hooks/useActionPlan";

interface IActionPlanProps {
  className?: string;
  assetId: string | undefined;
}

const ActionPlan = ({ className, assetId }: IActionPlanProps) => {
  const { actionPlan, handleActionPlanItemClick } = useActionPlan({ assetId });
  if (actionPlan) {
    if (actionPlan.status === Statuses.Loading) {
      return <Progress />;
    } else if (actionPlan.status === Statuses.Succeeded) {
      return !isEmpty(actionPlan.data) && actionPlan.data !== null ? (
        <Grid
          dataQa="action-plan"
          className={className}
          dataOrConfig={actionPlan.data}
          ItemComponent={({ data }: any) => <ActionPlanItem issue={data} />}
          onClick={handleActionPlanItemClick}
        />
      ) : (
        <DataNotFoundMessage />
      );
    } else if (actionPlan.status === Statuses.NotFound) {
      return <DataNotFoundMessage />;
    } else if (
      some(
        [Statuses.NetworkError, Statuses.Unauthorized, Statuses.Failed],
        (x) => actionPlan.status === x
      )
    ) {
      return <ApplicationErrorMessage />;
    }
  }

  return null;
};

const StyledActionPlan = styled(ActionPlan)``;

export default StyledActionPlan;
