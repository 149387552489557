// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IRootState } from "reducers";

const getRecentSearchesSelector = createSelector(
  (state: IRootState) => state.search.recentSearches,
  recentSearches => recentSearches
);

export default getRecentSearchesSelector;
