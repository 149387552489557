// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { Children } from "react";
import { FormattedMessage, MessageDescriptor } from "react-intl";
import styled from "styled-components";

import { Container, Typography, Grid } from "@material-ui/core";

import { colorGray40 } from "core/styles/ColorVariables";
import { spacingXXLarge } from "core/styles/StyleVariables";

interface ISection extends React.PropsWithChildren {
  title?: MessageDescriptor;
  className?: string;
}

const Section = ({ title, className, children }: ISection) => (
  <Container className={className}>
    <Grid className="section" container spacing={3}>
      {title && (
        <Grid item>
          <Typography variant="h6" component="h5">
            <FormattedMessage
              id={title.id}
              defaultMessage={title.defaultMessage}
            />
          </Typography>
        </Grid>
      )}
      {Children.toArray(children).map((child, i) => (
        <Grid className="item" item key={i}>
          <div>{child}</div>
        </Grid>
      ))}
    </Grid>
  </Container>
);

const StyledSection = styled(Section)`
  padding: 1em;

  .section {
    flex-direction: column;

    .item {
      margin-left: ${spacingXXLarge};
      border-top: solid 1px ${colorGray40};
      border-bottom: solid 1px ${colorGray40};
    }

    .item + .item {
      border-top: none;
    }
  }
`;

export default StyledSection;
