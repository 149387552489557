// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useMemo, MouseEvent } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { Tooltip } from "@material-ui/core";

import CountIconInfo from "common/components/CountIconInfo";
import DistanceFormatter, { DistanceService } from "common/formatters/Distance";
import useMaps from "common/hooks/useMaps";
import Icon from "common/components/Icon";

import { spacingNormal } from "core/styles/StyleVariables";

interface IDistanceProps {
  className?: string;
  distanceInKm: number;
  latitude: number | undefined | null;
  longitude: number | undefined | null;
  color: string;
}

const Distance = ({
  className,
  distanceInKm,
  latitude,
  longitude
}: IDistanceProps) => {
  const intl = useIntl();
  const openMaps = useMaps();

  const hasCoordinates = useMemo(
    () =>
      latitude !== undefined &&
      latitude !== null &&
      longitude !== undefined &&
      longitude !== null,
    [latitude, longitude]
  );

  const handleDistanceClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      if (hasCoordinates) {
        e.stopPropagation();
        openMaps(latitude as number, longitude as number);
      }
    },
    [hasCoordinates, latitude, longitude, openMaps]
  );

  return (
    <Tooltip title={<DistanceFormatter distanceKm={distanceInKm} />}>
      <div className={className} onClick={handleDistanceClick}>
        <CountIconInfo
          dataQa="distance"
          Icon={() => <Icon name="explore" dataQa="distance-icon" />}
          text={DistanceService.format(distanceInKm, intl)}
        />
      </div>
    </Tooltip>
  );
};

const StyledDistance = styled(Distance)`
  display: flex;
  color: ${(props) => props.color};

  .icon {
    color: ${(props) => props.color};
  }

  button {
    margin-left: ${spacingNormal};
  }
`;

export default StyledDistance;
