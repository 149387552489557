// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getLocationSelector from "./getLocationSelector";

const getLocationDataSelector = createSelector(
  getLocationSelector,
  locationSelector => (
    organization: string | null,
    location: string | null,
    region: string | null
  ) => {
    const locationResponse = locationSelector(organization, location, region);
    return locationResponse ? locationResponse.data : undefined;
  }
);

export default getLocationDataSelector;
