// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { getDataKey } from "../actions/IssueDetailsActions";
import IIssueDetails from "../models/IIssueDetails";

const getIssueDetailsSelector = createSelector(
  (state: IRootState) => state.data,
  (data) => (issueId: string | undefined) =>
    issueId
      ? (data[getDataKey(issueId)] as ApiResponse<IIssueDetails>)
      : undefined
);

export default getIssueDetailsSelector;
