// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { spacingLarge } from "../../../core/styles/StyleVariables";

interface IIssueRecommendationProps {
  className?: string;
  recommendation: string;
}

const IssueRecommendation = ({
  className,
  recommendation
}: IIssueRecommendationProps) => {
  return (
    <div className={className} data-qa="issue-recommendation">
      <Typography variant="overline">
        <FormattedMessage
          defaultMessage="recommendation"
          id={`mobile.issues.issue_details.recommendation`}
        />
      </Typography>
      <Typography variant="body1" className="content">
        {recommendation}
      </Typography>
    </div>
  );
};

const styledIssueRecommendation = styled(IssueRecommendation)`
  .content {
    margin-bottom: ${spacingLarge};
    font-weight: bold;
  }
`;

export default styledIssueRecommendation;
