// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";

import getActionPlanSelector from "../../../selectors/getActionPlanSelector";
import IIssue from "features/assetdetails/models/IIssue";
import useNavigate from "core/hooks/useNavigate";

interface IUseActionPlanOptions {
  assetId: string | undefined;
}

const useActionPlan = ({ assetId }: IUseActionPlanOptions) => {
  const actionPlanSelector = useSelector(getActionPlanSelector);
  const actionPlan = useMemo(
    () => actionPlanSelector(assetId),
    [actionPlanSelector, assetId]
  );
  const navigate = useNavigate();

  const handleActionPlanItemClick = useCallback(
    ({ Id }: IIssue) => {
      navigate({
        pathname: `/issues/${Id}`
      });
    },
    [navigate]
  );

  return { actionPlan, handleActionPlanItemClick };
};

export default useActionPlan;
