// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useEffect } from "react";
import { useParams } from "react-router";
import styled from "styled-components";

import { Box } from "@material-ui/core";

import Layout from "core/components/Layout";
import useAppBar from "core/hooks/useAppBar";
import { colorContentAreaLight } from "core/styles/ColorVariables";

import SearchAppBar from "./SearchAppBar";
import RecentSearches from "./RecentSearches";

interface ISearchProps {
  className?: string;
}

const Search = ({ className }: ISearchProps) => {
  const { setAppBarContent, setAppBarBackArrowVisible, setMoreIconVisible } =
    useAppBar();
  const { context } = useParams<{ context: string }>();

  useEffect(() => {
    setAppBarBackArrowVisible(false);
    setMoreIconVisible(false);
    setAppBarContent(() => <SearchAppBar context={context || ""} />);
  }, [
    setAppBarContent,
    setAppBarBackArrowVisible,
    setMoreIconVisible,
    context
  ]);

  return (
    <Layout>
      <Box className={className}>
        <RecentSearches />
      </Box>
    </Layout>
  );
};

const StyledSearch = styled(Search)`
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: ${colorContentAreaLight};
`;

export default StyledSearch;
