// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsCircuitBreaker = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M20 12h-8v3H5.83a3.001 3.001 0 100 2H12v3h8v-3h6.17A3.001 3.001 0 0032 16a3 3 0 00-5.83-1H20v-3z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsCircuitBreaker;
