// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Grid, Container, Typography } from "@material-ui/core";

const TenantSwitchHeader = () => {
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h5">
            <FormattedMessage
              defaultMessage="Select Tenant"
              id="mobile.sidepanel.tenant_switch.title"
            />
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default styled(TenantSwitchHeader)``;
