// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { Grid } from "@material-ui/core";

import { spacingLarge } from "../../../core/styles/StyleVariables";

export type Direction = "column" | "row";

export interface IIconProps {
  className?: string;
}

export interface IMessagesProps {
  className?: string;
}

interface IMessageProps {
  Icon: (props: IIconProps) => JSX.Element;
  Message: (props: IMessagesProps) => JSX.Element;
  direction?: Direction;
  className?: string;
}

const Message = ({
  Icon,
  Message,
  direction = "row",
  className
}: IMessageProps) => (
  <Grid
    container
    direction={direction}
    justifyContent="center"
    alignItems="center"
    className={`message ${className} ${direction}`}
  >
    <Icon></Icon>
    <Message></Message>
  </Grid>
);

const StyledMessage = styled(Message)`
  height: 100%;
  width: 100%;

  &.column .icon {
    margin-bottom: ${spacingLarge};
  }

  &.row .icon {
    margin-right: ${spacingLarge};
  }

  .icon {
    font-size: 24px;
  }
`;

export default StyledMessage;
