// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import {
  loadDataAction,
  removeResponseAction,
} from "../../../core/actions/DataActions";

const issueDetailsUrl = `Issues/{issueId}`;

const dataKey = "issueDetails";

export const getDataKey = (issueId: string) => `${dataKey}-${issueId}`;

export const loadIssueDetails = (issueId: string) => {
  const url = getApiUrl(issueDetailsUrl, [{ name: "issueId", value: issueId }]);
  return loadDataAction(getDataKey(issueId), endpoint.get, url);
};

export const removeIssueDetails = (issueId: string) =>
  removeResponseAction(getDataKey(issueId));
