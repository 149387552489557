// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { spacingLarge } from "../../../core/styles/StyleVariables";

interface IIssueMaintenanceProps {
  className?: string;
  maintenance: number;
  totalNumber: number;
}

const IssueMaintenance = ({
  className,
  maintenance,
  totalNumber,
}: IIssueMaintenanceProps) => {
  return (
    <div className={className} data-qa="issue-maintenance">
      <Typography variant="overline">
        <FormattedMessage
          defaultMessage="maintenance"
          id={`mobile.issues.issue_details.maintenance`}
        />
      </Typography>
      <Typography variant="body1" className="content">
        <FormattedMessage
          id="mobile.issues.issue_details.maintenance.out_of"
          defaultMessage="#{maintenance} out of {totalNumber}"
          values={{
            maintenance,
            totalNumber,
          }}
        />
      </Typography>
    </div>
  );
};

const styledIssueMaintenance = styled(IssueMaintenance)`
  .content {
    margin-bottom: ${spacingLarge};
    font-weight: bold;
  }
`;

export default styledIssueMaintenance;
