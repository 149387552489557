// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { MutableRefObject, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

const useResizeObserver = (
  targetRef: MutableRefObject<HTMLElement | undefined>,
  resizeHandler: () => void
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(resizeHandler);
    const targetElement = targetRef.current;
    if (targetElement) resizeObserver.observe(targetElement);
    return () => {
      if (targetElement) resizeObserver.unobserve(targetElement);
    };
    // eslint-disable-next-line
  }, []);
};

export default useResizeObserver;
