// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import endpoint, { getApiUrl } from "@apm/endpoint";

import { loadDataAction, removeResponseAction } from "core/actions/DataActions";

const actionPlanUrl = "mobile/assets/issues";

const dataKey = "actionPlan";

export const getDataKey = (assetId: string) => `${dataKey}-${assetId}`;

export const loadActionPlan = (assetId: string) => {
  const url = getApiUrl(
    actionPlanUrl,
    [],
    [
      { name: "assetId", value: assetId },
      { name: "status", value: "InProgress" },
      { name: "status", value: "Monitor" },
      { name: "status", value: "New" },
    ]
  );
  return loadDataAction(getDataKey(assetId), endpoint.get, url);
};

export const removeActionPlan = (assetId: string) =>
  removeResponseAction(getDataKey(assetId));
