// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Map } from "immutable";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import Risk from "common/models/Risk";
import AssetsTransformer from "icons/AssetsTransformer";
import AssetsAcVoltageSource from "icons/AssetsAcVoltageSource";
import AssetsBattery from "icons/AssetsBattery";
import AssetsCapacitor from "icons/AssetsCapacitor";
import AssetsCircuitBreaker from "icons/AssetsCircuitBreaker";
import AssetsRelay from "icons/AssetsRelay";
import AssetsSwitch from "icons/AssetsSwitch";
import AssetsTransmissionLine from "icons/AssetsTransmissionLine";

import {
  colorRiskUnknown,
  colorRiskLow,
  colorRiskMedium,
  colorRiskHigh,
  colorGray0
} from "core/styles/ColorVariables";
import { spacingSmall } from "core/styles/StyleVariables";

const assetIconSize = "40px";

const assetTypeIconMap = Map({
  acvoltagesource: "ac_voltage_source",
  battery: "assets_battery",
  cable: "assets_cable",
  capacitor: "assets_capacitor",
  cct: "assets_CVT_CCVT",
  ccvt: "assets_CVT_CCVT",
  circuitbreaker: "assets_circuit_breaker",
  dcvoltagesource: "assets_dc_voltage_source",
  relay: "assets_relay",
  switch: "assets_switch",
  transformertap: "assets_transformer_tap",
  transformer: "assets_transformer",
  transmissionline: "assets_transmission_line"
});

interface IAssetIconProps {
  className?: string;
  risk: Risk | undefined;
  type: string | null | undefined;
}

const AssetIcon = ({ className, risk, type }: IAssetIconProps) => {
  const iconName = useMemo(() => {
    const normalizedType = type
      ? type.toLowerCase().replace(/ /g, "")
      : undefined;

    if (normalizedType === undefined) return "assets_generic";
    for (let assetType of assetTypeIconMap.keySeq().toArray()) {
      if (normalizedType.indexOf(assetType) >= 0)
        return assetTypeIconMap.get(assetType) || "assets_generic";
    }

    return "assets_generic";
  }, [type]);

  const renderAssetIconComponent = useCallback(() => {
    if (iconName === "assets_transformer")
      return (
        <AssetsTransformer
          className={`${className} ${risk}`}
          data-qa="mobile-icon-transformer"
        />
      );
    else if (iconName === "assets_ac_voltage_source")
      return <AssetsAcVoltageSource className={`${className} ${risk}`} />;
    else if (iconName === "assets_battery")
      return (
        <AssetsBattery
          className={`${className} ${risk}`}
          data-qa="mobile-icon-battery"
        />
      );
    else if (iconName === "assets_capacitor")
      return <AssetsCapacitor className={`${className} ${risk}`} />;
    else if (iconName === "assets_circuit_breaker")
      return (
        <AssetsCircuitBreaker
          className={`${className} ${risk}`}
          data-qa="mobile-icon-circuit-breaker"
        />
      );
    else if (iconName === "assets_relay")
      return <AssetsRelay className={`${className} ${risk}`} />;
    else if (iconName === "assets_switch")
      return <AssetsSwitch className={`${className} ${risk}`} />;
    else if (iconName === "assets_transmission_line")
      return <AssetsTransmissionLine className={`${className} ${risk}`} />;
    else return <div className={`${className} ${risk} empty`} />;
  }, [className, iconName, risk]);

  return <div className="asset-icon-wrapper">{renderAssetIconComponent()}</div>;
};

const StyledAssetIcon = styled(AssetIcon)`
  &.asset-icon-wrapper {
    width: 40px;
  }

  background-color: ${colorRiskUnknown};
  font-size: 1.9rem;
  padding: ${spacingSmall};
  border-radius: 20%;

  path {
    fill: ${colorGray0};
  }

  &.Unknown {
    background-color: ${colorRiskUnknown};
  }

  &.Low {
    background-color: ${colorRiskLow};
  }

  &.Medium {
    background-color: ${colorRiskMedium};
  }

  &.High {
    background-color: ${colorRiskHigh};
  }

  &.empty {
    width: ${assetIconSize};
    height: ${assetIconSize};
  }
`;

export default StyledAssetIcon;
