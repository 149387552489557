// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useRef
} from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import {
  AppBar as MaterialUiAppBar,
  Toolbar,
  Container,
  Box
} from "@material-ui/core";

import useResizeObserver from "core/hooks/useResizeObserver";
import { colorGray100 } from "core/styles/ColorVariables";
import { spacingNormal } from "core/styles/StyleVariables";

import BackArrowButton from "./BackArrowButton";
import Collapsible from "./Collapsible";
import MoreIconButton from "./MoreIconButton";
import getAppBarContentSelector from "../../selectors/getAppBarContentSelector";
import getAppBarCollapsibleContentSelector from "../../selectors/getAppBarCollapsibleContentSelector";
import getAppBarBackArrowVisibleSelector from "../../selectors/getAppBarBackArrowVisibleSelector";
import getAppBarMoreIconVisibleSelector from "../../selectors/getAppBarMoreIconVisibleSelector";

export interface IAppBarContext {
  collapseButtonClientHeight?: number;
  appBarClientHeight?: number;
}

export const AppBarContext = createContext<IAppBarContext>({});

interface IAppBarProps extends React.PropsWithChildren {
  className?: string;
}

const AppBar = ({ className, children }: IAppBarProps) => {
  const Content = useSelector(getAppBarContentSelector);
  const CollapsibleContent = useSelector(getAppBarCollapsibleContentSelector);
  const backArrowVisible = useSelector(getAppBarBackArrowVisibleSelector);
  const moreIconVisible = useSelector(getAppBarMoreIconVisibleSelector);

  const [collapsibleVisible, setCollapsibleVisible] = useState(false);

  const handleVisibleChange = useCallback(
    (visible: boolean) => setCollapsibleVisible(visible),
    [setCollapsibleVisible]
  );

  useEffect(() => {
    if (!CollapsibleContent) setCollapsibleVisible(false);
  }, [CollapsibleContent]);

  const appBarRef = useRef<HTMLDivElement>();
  const [appBarContextState, setAppBarContextState] = useState<IAppBarContext>({
    collapseButtonClientHeight: 100
  });
  const resizeHandler = useCallback(() => {
    setAppBarContextState((contextState) => {
      if (appBarRef.current) {
        contextState = {
          ...contextState,
          appBarClientHeight: appBarRef.current.clientHeight
        };
      }
      return contextState;
    });
  }, []);
  useResizeObserver(appBarRef, resizeHandler);

  return (
    <AppBarContext.Provider value={appBarContextState}>
      <MaterialUiAppBar
        className={`
        ${className}
        ${CollapsibleContent ? "animate" : ""}
        ${collapsibleVisible ? "visible" : "hidden"}
      `}
        position="sticky"
        innerRef={appBarRef}
      >
        <Toolbar className="nav-toolbar-container" disableGutters={true}>
          <Box className="nav-toolbar">
            {backArrowVisible && (
              <BackArrowButton className="nav-back-button" />
            )}
            <Container
              className={`
                nav-toolbar-content
                ${
                  backArrowVisible && moreIconVisible
                    ? "margin-2"
                    : backArrowVisible || moreIconVisible
                    ? "margin-1"
                    : ""
                }
              `}
            >
              <Box>{Content && <Content />}</Box>
            </Container>
            {moreIconVisible && <MoreIconButton />}
          </Box>
          {CollapsibleContent && (
            <Collapsible
              visible={collapsibleVisible}
              Content={CollapsibleContent}
              onVisibleChange={handleVisibleChange}
            />
          )}
          {React.Children.toArray(children)}
        </Toolbar>
      </MaterialUiAppBar>
    </AppBarContext.Provider>
  );
};

const StyledAppBar = styled(AppBar)`
  &.visible {
    box-shadow: none;
  }

  .nav-toolbar-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: ${colorGray100};
    height: 100%;

    & .nav-toolbar {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: ${spacingNormal};
      width: 100%;

      .nav-back-button + .nav-toolbar-content {
        padding-left: 0;
        padding-right: 0;
      }

      .nav-toolbar-content {
        overflow: hidden;
        padding-right: 0;
      }

      &.margin-1 .nav-toolbar-content {
        width: calc(100% - 48px);
      }

      &.margin-2 .nav-toolbar-content {
        width: calc(100% - 96px);
      }
    }
  }

  .icon {
    color: ${colorGray100};
  }
`;

export default StyledAppBar;
