// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Box } from "@material-ui/core";

import useAppBar from "core/hooks/useAppBar";
import useSearchComponents from "core/hooks/useSearchComponents";

import Layout from "core/components/Layout";
import LocationsAppBar from "./LocationsAppBar";
import LocationsList from "../LocationsList";

const searchContext = "locations";

interface ILocations {
  className?: string;
}

const Locations = ({ className }: ILocations) => {
  const [totalLocations, setTotalLocations] = useState<number>();
  const { setAppBarContent } = useAppBar();

  useSearchComponents(searchContext);

  useEffect(() => {
    setAppBarContent(() => <LocationsAppBar totalLocations={totalLocations} />);
  }, [setAppBarContent, totalLocations]);

  return (
    <Layout>
      <Box className={className} p={0}>
        <LocationsList onTotalLocationsUpdate={setTotalLocations} />
      </Box>
    </Layout>
  );
};

const StyledLocations = styled(Locations)`
  height: 100%;
`;

export default StyledLocations;
