// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import styled from "styled-components";

import { Typography, Box, useTheme } from "@material-ui/core";

import LocationDetails from "common/components/LocationDetails";
import { spacingNormal, spacingXLarge } from "core/styles/StyleVariables";
import SearchParams from "core/models/SearchParams";

import RiskDot from "./RiskDot";
import ILocation from "../models/ILocation";
import useNavigate from "core/hooks/useNavigate";

interface ILocationListItemProps {
  className?: string;
  data: ILocation;
}

const LocationListItem = ({ className, data }: ILocationListItemProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleLocationClick = useCallback(() => {
    const searchParams = new SearchParams({
      organization: data.OrganizationName || "",
      location: data.LocationName || "",
      region: data.RegionName || ""
    });

    navigate({
      pathname: "/locations/assets",
      search: searchParams.toString()
    });
  }, [navigate, data]);

  return (
    <Box className={className} onClick={handleLocationClick}>
      <Box className="risk">
        <RiskDot risk={data.Risk} />
      </Box>
      <Box className="info">
        <Typography className="location-name" variant="body1">
          {data.LocationName}
        </Typography>
        <LocationDetails
          organization={data.OrganizationName}
          region={data.RegionName}
          assetsCount={data.AssetsCount}
          issuesCount={data.IssuesCount}
          distanceInKm={data.Distance}
          latitude={data.Latitude}
          longitude={data.Longitude}
          color={theme.palette.grey[900]}
        />
      </Box>
    </Box>
  );
};

const StyledLocationListItem = styled(LocationListItem)`
  width: 100%;
  display: flex;
  flex-direction: row;

  & > div + div {
    margin-left: ${spacingXLarge};
  }

  > .info {
    display: flex;
    flex-direction: column;

    .location-name {
      font-weight: bold;
    }
  }

  > .risk {
    margin-top: ${spacingNormal};
  }
`;

export default StyledLocationListItem;
