// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useState, useCallback } from "react";

interface IUseAccordionOptions {
  allowToExpand?: boolean;
}

const useAccordion = ({ allowToExpand = true }: IUseAccordionOptions) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = useCallback(() => {
    if (allowToExpand) setExpanded(!expanded);
  }, [allowToExpand, setExpanded, expanded]);

  return { expanded, handleClick };
};

export default useAccordion;
