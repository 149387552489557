// Copyright 2016-2024 Hitachi Energy. All rights reserved.

const isEqual = (
  a: string | null | undefined,
  b: string | null | undefined
) => {
  if ((a === null || a === undefined) && (b === null || b === undefined))
    return true;

  return a === b;
};

export default isEqual;
