// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Location } from "history";

import SearchParams from "core/models/SearchParams";

const useQueryParams = (location: Location) => {
  const searchParams = new SearchParams(location.search);
  return {
    organization: searchParams.get("organization"),
    location: searchParams.get("location"),
    region: searchParams.get("region")
  };
};

export default useQueryParams;
