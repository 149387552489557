// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import useGeolocation from "core/hooks/useGeolocation";
import { loadLocation, removeLocation } from "../actions/LocationActions";
import useQueryParams from "../hooks/useQueryParams";

const useDataFunctions = () => {
  const dispatch = useDispatch();
  const getCurrentPosition = useGeolocation();
  const location = useLocation();
  const queryParams = useQueryParams(location);

  const loadData = useCallback(async () => {
    let position: GeolocationPosition | undefined;
    try {
      position = await getCurrentPosition();
    } catch (e) {
      // Hide "User denied Geolocation" error
    } finally {
      dispatch(
        loadLocation(
          queryParams.organization,
          queryParams.location,
          queryParams.region,
          position && position.coords.latitude,
          position && position.coords.longitude
        )
      );
    }
  }, [dispatch, getCurrentPosition, queryParams]);

  const removeData = useCallback(() => {
    dispatch(
      removeLocation(
        queryParams.organization,
        queryParams.location,
        queryParams.region
      )
    );
  }, [dispatch, queryParams]);

  return {
    loadData,
    removeData
  };
};

export default useDataFunctions;
