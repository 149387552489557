// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { IMessagesProps } from "../Message";
import { colorGray50 } from "core/styles/ColorVariables";

const DataNotFoundMessageMessages = styled(({ className }: IMessagesProps) => (
  <Typography className={className} variant="body1">
    <FormattedMessage
      defaultMessage="No data found"
      id="mobile.data_not_found_message"
    />
  </Typography>
))`
  color: ${colorGray50};
`;

export default DataNotFoundMessageMessages;
