// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { ApiResponse } from "@apm/endpoint";

import { IRootState } from "reducers";

import { dataKey } from "../actions/VersionActions";
import IVersion from "../models/IVersion";

const getVersionSelector = createSelector(
  (state: IRootState) => state.data[dataKey],
  (version: ApiResponse<IVersion>) => version
);

export default getVersionSelector;
