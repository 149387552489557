// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";

import { Button } from "@material-ui/core";

import { IButtonsProps } from "../MessageScreen";
import Icon from "common/components/Icon";

import {
  colorGray90,
  colorGray0,
  colorGray80
} from "../../../../core/styles/ColorVariables";
import useNavigate from "core/hooks/useNavigate";

const NotFoundButtons = styled(({ className }: IButtonsProps) => {
  const navigate = useNavigate();

  const handleHomeButtonClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className={className}>
      <Button
        variant="contained"
        size="medium"
        onClick={handleHomeButtonClick}
        className={className}
      >
        <Icon name="home" className="home-icon" />
        <FormattedMessage
          id="mobile.not_found_screen.home"
          defaultMessage="Back to Homepage"
        />
      </Button>
    </div>
  );
})`
  button {
    background-color: ${colorGray90};
    color: ${colorGray0};

    &:active {
      background-color: ${colorGray80};
    }

    &:focus {
      background-color: ${colorGray90};
    }
  }
`;

export default NotFoundButtons;
