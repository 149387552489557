// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { IRootState } from "reducers";

const getCollapsibleContentSelector = createSelector(
  (state: IRootState) => state.ui.appBar.CollapsibleContent,
  CollapsibleContent => CollapsibleContent
);

export default getCollapsibleContentSelector;
