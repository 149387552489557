// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";
import IModelDetails from "../models/IModelDetails";

export class ModelMessageService {
  public static format(
    intl: IntlShape,
    model: IModelDetails | null,
    parameterId: string,
    value: any
  ) {
    if (!model) return value;
    return intl.formatMessage({
      id: `${model.modelId}.${parameterId}.${value}`,
      defaultMessage: intl.formatMessage({
        id: `${model.implementationId}.${parameterId}.${value}`,
        defaultMessage: value
      })
    });
  }
}

export default ModelMessageService;
