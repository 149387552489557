// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Action, Reducer } from "redux";

import { Actions, ISetUserTenantAction } from "core/actions/TenantsActions";
import ITenant from "core/models/ITenant";

export interface IState {
  userTenant: ITenant | undefined;
}

export const defaultState: IState = {
  userTenant: undefined
};

export type AppActions = Action<string>;

const reducer: Reducer<IState> = (
  state: IState = defaultState,
  action: AppActions
) => {
  switch (action.type) {
    case Actions.App_SetUserTenant:
      const { tenant } = action as ISetUserTenantAction;
      return {
        ...state,
        userTenant: tenant
      };
    default:
      return state;
  }
};

export default reducer;
