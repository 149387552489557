// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { useSelector } from "react-redux";

import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import Progress from "common/components/Progress";
import INameplate from "features/assetdetails/models/IAssetNameplate";
import AssetNameplateItems from "./AssetNameplateItems";
import getAssetDetailsControlFlagsSelector from "../../selectors/getAssetDetailsControlFlagsSelector";
import getAssetDetailsNameplateSelector from "../../selectors/getAssetDetailsNameplateSelector";

interface IAssetNameplateProps {
  assetId: string | undefined;
}

const AssetNameplate = ({ assetId }: IAssetNameplateProps) => {
  const assetDetailsControlFlagsSelector = useSelector(
    getAssetDetailsControlFlagsSelector
  );
  const assetDetailsNameplateSelector = useSelector(
    getAssetDetailsNameplateSelector
  );

  const {
    hasStatusLoading,
    hasStatusSucceeded,
    hasStatusFailed,
    hasStatusNotFound,
    hasNameplate
  } = assetDetailsControlFlagsSelector(assetId);
  const nameplate = assetDetailsNameplateSelector(assetId);

  return (
    <>
      {hasStatusLoading && <Progress />}
      {hasStatusSucceeded && hasNameplate && (
        <AssetNameplateItems nameplate={nameplate as INameplate} />
      )}
      {hasStatusSucceeded && !hasNameplate && <DataNotFoundMessage />}
      {hasStatusNotFound && <DataNotFoundMessage direction="column" />}
      {hasStatusFailed && <ApplicationErrorMessage direction="column" />}
    </>
  );
};

export default AssetNameplate;
