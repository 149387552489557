// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useLayoutEffect, useEffect } from "react";
import { useLocation } from "react-router-dom";

import Layout from "core/components/Layout";
import useAppBar from "core/hooks/useAppBar";
import LocationAppBar from "./LocationAppBar";
import AssetsGrid from "./AssetsGrid";
import useDataFunctions from "../hooks/useDataFunctions";
import useQueryParams from "../hooks/useQueryParams";
import hasRequiredQueryParams from "../utils/hasRequiredQueryParams";
import useNavigate from "core/hooks/useNavigate";

const AssetsList = () => {
  const { setAppBarContent } = useAppBar();
  const { loadData, removeData } = useDataFunctions();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = useQueryParams(location);

  useLayoutEffect(() => {
    if (!hasRequiredQueryParams(location)) navigate("/", { replace: true });
  }, [navigate, location]);

  useEffect(() => {
    setAppBarContent(() => (
      <LocationAppBar
        organization={queryParams.organization}
        location={queryParams.location}
        region={queryParams.region}
      />
    ));
  }, [queryParams, setAppBarContent]);

  useEffect(() => {
    loadData();

    return () => {
      removeData();
    };
  }, [loadData, removeData]);

  return (
    <Layout>
      <AssetsGrid
        organization={queryParams.organization}
        location={queryParams.location}
        region={queryParams.region}
      />
    </Layout>
  );
};

export default AssetsList;
