// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getAssetDetailsSelector from "./getAssetDetailsSelector";

const getAssetDetailsNameplateSelector = createSelector(
  getAssetDetailsSelector,
  assetDetailsSelector => (assetId: string | undefined) => {
    const assetDetails = assetDetailsSelector(assetId);
    return assetDetails && assetDetails.data && assetDetails.data.Nameplate;
  }
);

export default getAssetDetailsNameplateSelector;
