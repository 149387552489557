// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { Box, Typography } from "@material-ui/core";

import AssetIcon from "common/components/AssetIcon";
import CountIconInfo from "common/components/CountIconInfo";
import { RatingService } from "common/formatters/Rating";
import SearchParams from "core/models/SearchParams";
import Icon from "common/components/Icon";
import IAssetSummary from "../models/IAssetSummary";

import {
  spacingXLarge,
  spacingSmall,
  spacingNormal,
  spacingLarge
} from "core/styles/StyleVariables";
import { colorStatusPurple } from "core/styles/ColorVariables";
import useNavigate from "core/hooks/useNavigate";

interface IAssetSummaryProps {
  data: IAssetSummary;
  className?: string;
}

const AssetSummary = ({ data, className }: IAssetSummaryProps) => {
  const {
    AssetId,
    IssuesCount,
    Risk,
    AssetType,
    Voltage,
    RatedPower,
    LifecycleStatus
  } = data;

  const navigate = useNavigate();
  const intl = useIntl();

  const handleClick = useCallback(() => {
    const searchParams = new SearchParams({
      assetId: AssetId
    });

    navigate({
      pathname: "/locations/assets/details",
      search: searchParams.toString()
    });
  }, [AssetId, navigate]);

  return (
    <Box
      className={`${className} asset-summary-wrapper`}
      data-qa="asset-summary"
      display="flex"
      onClick={handleClick}
    >
      <Box className="asset-icon">
        <AssetIcon risk={Risk} type={AssetType} />
      </Box>
      <div className="asset-info">
        <Typography className="asset-id" variant="body1">
          {AssetId}
        </Typography>
        <Box display="flex" className="asset-props">
          <CountIconInfo
            disabled={IssuesCount <= 0}
            Icon={() => (
              <Icon
                name="report"
                variant="outlined"
                dataQa="asset-issues-count-icon"
              />
            )}
            text={intl.formatNumber(data.IssuesCount)}
            dataQa="asset-issues-count"
          />
          <CountIconInfo
            disabled
            Icon={() => <Icon name="bolt" dataQa="asset-voltage-icon" />}
            text={RatingService.format(Voltage, RatedPower, intl)}
            dataQa="rating"
          />
          {LifecycleStatus === "InReserve" && (
            <div className="asset-status-in-reserve-icon-wrapper">
              <Icon
                name="inventory_2"
                variant="outlined"
                className="in-reserve-icon"
              />
            </div>
          )}
        </Box>
      </div>
    </Box>
  );
};

const StyledAssetSummary = styled(AssetSummary)`
  &.asset-summary-wrapper {
    align-items: center;
    column-gap: ${spacingLarge};
  }

  .asset-icon {
    margin: ${spacingSmall} ${spacingXLarge} ${spacingSmall} ${spacingNormal};
  }

  .asset-info {
    .asset-id {
      font-weight: bold;
    }

    .asset-props {
      > div {
        margin-right: ${spacingXLarge};
      }
    }
  }

  .asset-status-in-reserve-icon-wrapper {
    display: flex;
    align-items: center;

    .in-reserve-icon {
      color: ${colorStatusPurple};
    }
  }
`;

export default StyledAssetSummary;
