// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import BackToFunc from "./BackToFunc";
import BackToPath from "./BackToPath";
import { breadcrumbs } from "../RouterProvider";

interface IBackArrowButton {
  className?: string;
}

const BackArrowButton = ({ className }: IBackArrowButton) => {
  const location = useLocation();
  const breadcrumb = useMemo(() => {
    const filteredBreadcrumbs = breadcrumbs.filter(
      (b) => !!matchPath({ path: b.from, end: b.exact }, location.pathname)
    );
    return filteredBreadcrumbs.length ? filteredBreadcrumbs[0] : null;
  }, [location.pathname]);

  return breadcrumb && typeof breadcrumb.to !== "string" ? (
    <BackToFunc className={className} to={breadcrumb.to} />
  ) : breadcrumb && typeof breadcrumb.to === "string" ? (
    <BackToPath className={className} to={breadcrumb.to} />
  ) : null;
};

export default BackArrowButton;
