// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { isEmpty } from "lodash";

import { List, ListItem, Typography, Box } from "@material-ui/core";

import IAssetDetails from "features/assetdetails/models/IAssetDetails";
import DateString from "common/formatters/DateString";
import RiskDot from "features/locations/components/RiskDot";
import DegradationScore from "common/formatters/DegradationScore";
import SubscoreName from "common/formatters/SubscoreName";
import Accordion, { arrowWidth } from "common/components/Accordion";
import Subscores from "./Subscores";
import ChangeDirectionIndicator from "./ChangeDirectionIndicator";

import { spacingLarge } from "core/styles/StyleVariables";
import {
  colorGray20,
  colorTextSecondaryLight
} from "core/styles/ColorVariables";
import IModelDetails from "common/models/IModelDetails";
import NumericalValue from "common/formatters/NumericalValue";

interface IRiskDetailsProps {
  assetDetails: IAssetDetails;
  className?: string;
}

const RiskDetails = ({ assetDetails, className }: IRiskDetailsProps) => {
  const model: IModelDetails | null = assetDetails.Nameplate
    ? {
        modelId: assetDetails.Nameplate.ModelId || "",
        implementationId: assetDetails.Nameplate.ModelImplementationId || ""
      }
    : null;
  
  return (
    <List className={`risk-details ${className}`} data-qa="risk-details">
      <ListItem className="list-item-value">
        <Box>
          <Typography variant="body1">
            <FormattedMessage
              defaultMessage="Condition"
              id="mobile.asset_details.overview.condition"
            />
          </Typography>
          <DateString
            value={assetDetails.DegradationScoreExecutionDate}
            variant="caption"
            className="condition-date"
            showDateWithoutTime
          ></DateString>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <RiskDot risk={assetDetails.Risk} />
          {assetDetails.DegradationScore !== null && (
            <>
              <Typography variant="h5" className="value">
                <DegradationScore value={assetDetails.DegradationScore} />
              </Typography>
              <ChangeDirectionIndicator
                changeDirection={assetDetails.DegradationScoreChangeDirection}
              />
            </>
          )}
        </Box>
      </ListItem>
      {Object.entries(assetDetails.Scores).map(
        ([subscoreName, { Value, SubScores, ChangeDirection }], index) => {
          const allowToExpand = !isEmpty(SubScores);

          return (
            <ListItem className="list-item-value" key={subscoreName}>
              <Accordion
                className={`${allowToExpand ? "" : "non-expandable"}`}
                Content={() => (
                  <Subscores model={model} subscores={SubScores} />
                )}
                allowToExpand={allowToExpand}
              >
                <Typography variant="body1" className="name">
                  <SubscoreName model={model} nameId={subscoreName} />
                </Typography>
                <Typography variant="h5" className="value">
                  <NumericalValue value={Value} roundValue={false}/>
                </Typography>
                <ChangeDirectionIndicator changeDirection={ChangeDirection} />
              </Accordion>
            </ListItem>
          );
        }
      )}
    </List>
  );
};

const StyledRiskDetails = styled(RiskDetails)`
  ul {
    li {
      border-top: solid 1px ${colorGray20};
    }
  }

  .list-item-value {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .condition-date {
      color: ${colorTextSecondaryLight};
    }

    .name {
      flex: 1;
    }

    .value {
      font-weight: bold;
      margin-left: ${spacingLarge};
    }

    .non-expandable {
      padding-left: ${arrowWidth};
    }
  }
`;

export default StyledRiskDetails;
