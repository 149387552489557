// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import styled from "styled-components";

import { IIconProps } from "../MessageScreen";
import Icon from "common/components/Icon";

import { colorStatusOrange } from "../../../../core/styles/ColorVariables";

const NotFoundIcon = styled(({ className }: IIconProps) => (
  <Icon name="warning" className={className} />
))`
  color: ${colorStatusOrange};
`;

export default NotFoundIcon;
