// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import Message, { Direction } from "./../Message";
import DataNotFoundMessageMessages from "./DataNotFoundMessageMessages";
import DataNotFoundMessageIcon from "./DataNotFoundMessageIcon";

interface IDataNotFoundProps {
  direction?: Direction;
}

const DataNotFoundMessage = ({ direction }: IDataNotFoundProps) => {
  return (
    <Message
      Icon={DataNotFoundMessageIcon}
      Message={DataNotFoundMessageMessages}
      direction={direction}
    ></Message>
  );
};

export default DataNotFoundMessage;
