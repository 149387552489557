// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsRelay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.07 15H5.83a3.001 3.001 0 100 2h3.24a7.002 7.002 0 0013.86 0h3.24a3.001 3.001 0 100-2h-3.24a7.002 7.002 0 00-13.86 0z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsRelay;
