// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import DrawerTypes from "core/models/DrawerTypes";
import { IRootState } from "reducers";

const getDrawerContentSelector = createSelector(
  (state: IRootState) => state.ui.drawers,
  drawers => (drawerType: DrawerTypes) => {
    const drawer = drawers[drawerType];
    return drawer ? drawer.Content : undefined;
  }
);

export default getDrawerContentSelector;
