// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useCallback } from "react";
import styled from "styled-components";

import { FormattedMessage } from "react-intl";

import { Typography } from "@material-ui/core";

import useDrawer from "core/hooks/useDrawer";
import Icon from "common/components/Icon";
import useNavigate from "core/hooks/useNavigate";

interface ILicensesButtonProps {
  className?: string;
}

const LicensesButton = ({ className }: ILicensesButtonProps) => {
  const navigate = useNavigate();
  const { closeDrawer } = useDrawer("right");

  const handleLicensesButtonClick = useCallback(() => {
    navigate("/licenses");
    closeDrawer();
  }, [navigate, closeDrawer]);

  return (
    <div
      className={className}
      data-qa="licenses"
      onClick={handleLicensesButtonClick}
    >
      <Typography variant="body1">
        <FormattedMessage
          id="mobile.sidepanel.licenses"
          defaultMessage="Licenses"
        />
      </Typography>
      <Icon name="chevron_right" />
    </div>
  );
};

export default styled(LicensesButton)`
  display: flex;
  align-items: center;

  .icon {
    margin-left: 1ch;
  }
`;
