// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useState } from "react";
import styled from "styled-components";
import { ListItem, Box, Typography, Collapse, List } from "@material-ui/core";
import { FormattedMessage, FormattedTime } from "react-intl";

import DateString from "common/formatters/DateString";
import IIssueDetails from "../models/IIssueDetails";
import Icon from "common/components/Icon";

import {
  spacingXLarge,
  spacingLarge,
  spacingMedium,
  spacingSmall
} from "core/styles/StyleVariables";
import { colorGray20 } from "core/styles/ColorVariables";

interface IIssueCommentProps {
  className?: string;
  issueData: IIssueDetails;
}

const IssueComments = ({ className, issueData }: IIssueCommentProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ListItem onClick={handleClick} className={className}>
      <Box className="issue-comments">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box className="arrow" display="flex" alignItems="center">
            <Icon
              name="expand_more"
              className={open ? "down" : "right"}
              size="sm"
            />
          </Box>
          <Icon
            name="comment"
            variant="outlined"
            className="comment-icon"
            size="sm"
          />
          <Typography variant="overline">
            <FormattedMessage
              id="mobile.issues.issue_details.comments"
              defaultMessage="Comments ({numberOfComments})"
              values={{ numberOfComments: issueData.Comments.length }}
            />
          </Typography>
        </Box>
        {issueData.Comments.length !== 0 && issueData.Comments.length !== null && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List className="comments">
              {issueData.Comments.map((c, i) => {
                const date = c.Date && (
                  <DateString value={c.Date} variant="caption" showDateWithoutTime />
                );

                const time = c.Date && (
                  <Typography data-qa="precise-time" variant="caption">
                    <FormattedTime value={c.Date} />
                  </Typography>
                );

                return (
                  <ListItem key={i}>
                    <Typography component="div" className="comment">
                      {(c.Author.Name || c.Author.Surname) && (
                        <Typography variant="body1" className="author">
                          <FormattedMessage
                            id="mobile.issues.issue_details.comments.author"
                            defaultMessage="{name} {surname}"
                            values={{
                              name: c.Author.Name,
                              surname: c.Author.Surname
                            }}
                          />
                        </Typography>
                      )}
                      {(c.Author.Name === null && c.Author.Surname === null) && (
                        <Typography variant="body1" className="author">
                          <FormattedMessage
                            defaultMessage="Unknown User"
                            id="mobile.issues.issue_details.comments.unknown"
                          />
                        </Typography>
                      )}
                      <FormattedMessage
                        id="mobile.issues.issue_details.comments.date"
                        defaultMessage="{date}, {time}"
                        values={{ date, time }}
                      />
                      <Typography variant="body1" className="content">
                        {c.CommentText}
                      </Typography>
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        )}
      </Box>
    </ListItem>
  );
};

const styledIssueComment = styled(IssueComments)`
  .author {
    font-weight: bold;
  }

  .issue-comments {
    width: 100%;
  }

  ul {
    margin-left: ${spacingXLarge};
    margin-right: ${spacingXLarge};

    li {
      border-top: solid 1px ${colorGray20};
      border-bottom: none;
    }
  }

  .icon {
    transition: transform 0.2s ease-in-out;
  }

  .down {
    transform: rotate(0deg);
    margin-right: ${spacingLarge};
  }

  .right {
    transform: rotate(-90deg);
    margin-right: ${spacingLarge};
  }

  .comment-icon {
    margin-right: ${spacingMedium};
  }

  .comments {
    padding-right: ${spacingLarge};
    margin-right: none;

    .comment {
      padding-bottom: ${spacingSmall};
      padding-top: ${spacingSmall};

      .content {
        margin-top: ${spacingMedium};
      }
    }
  }
`;

export default styledIssueComment;
