// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import styled from "styled-components";

import { Container, Grid, Typography } from "@material-ui/core";

import IUser from "core/models/IUser";
import getUserSelector from "core/selectors/getUserSelector";

interface IUserInfoProps {
  className?: string;
}

const UserInfo = ({ className }: IUserInfoProps) => {
  const userInfo = useSelector(getUserSelector);

  if (!isEmpty(userInfo.data)) {
    const { Name, Surname, Login } = userInfo.data as IUser;
    return (
      <Container className={className}>
        <Grid container spacing={4}>
          <Grid className="user-info" item>
            <Typography className="user-name" variant="h6">
              {Name} {Surname}
            </Typography>
            <Typography className="user-login" variant="body1">
              {Login}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return null;
};

const StyledUserInfo = styled(UserInfo)`
  padding: 1em;

  .user-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;

    .user-name {
      text-align: end;
      word-break: break-word;
    }

    .user-login {
      text-align: end;
      word-break: break-word;
    }
  }
`;

export default StyledUserInfo;
