// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useState } from "react";
import styled from "styled-components";
import { ListItem, Box, Typography, Collapse, List } from "@material-ui/core";
import { FormattedTime, FormattedMessage } from "react-intl";

import DateString from "common/formatters/DateString";
import IWorkOrder from "../models/IWorkOrder";
import IWorkRequest from "../models/IWorkRequest";
import Icon from "common/components/Icon";

import { colorGray20 } from "core/styles/ColorVariables";
import {
  spacingXLarge,
  spacingLarge,
  spacingMedium,
  spacingSmall
} from "core/styles/StyleVariables";

interface IIssueActionProps {
  className?: string;
  workOrders: IWorkOrder[];
  workRequest: IWorkRequest;
}

const IssueActions = ({
  className,
  workOrders,
  workRequest
}: IIssueActionProps) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const numberOfActions =
    workRequest !== null ? workOrders.length + 1 : workOrders.length;

  const workRequestDueDate = workRequest && (
    <DateString value={workRequest.DueDate} variant="caption"></DateString>
  );

  const workRequestTime = workRequest && workRequest.DueDate !== null && (
    <FormattedTime value={workRequest.DueDate}></FormattedTime>
  );

  const workRequestStatus =
    workRequest && workRequest.Status ? workRequest.Status : null;

  const workRequests =
    workRequest && workRequest.DueDate !== null ? (
      <FormattedMessage
        id="mobile.issues.issue_details.work_request_date"
        defaultMessage="{status}; Due: {date}, {time}"
        values={{
          status: workRequestStatus,
          date: workRequestDueDate,
          time: workRequestTime
        }}
      />
    ) : (
      <FormattedMessage
        id="mobile.issues.issue_details.work_request_no_date"
        defaultMessage="{status}; Due: {date}"
        values={{
          status: workRequestStatus,
          date: workRequestDueDate
        }}
      />
    );

  return (
    <ListItem onClick={handleClick} className={className}>
      <Box className="issue-actions">
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box className="arrow" display="flex" alignItems="center">
            <Icon
              name="expand_more"
              className={open ? "down" : "right"}
              size="sm"
            />
          </Box>
          <Icon name="construction" className="comment-icon" size="sm" />
          <Typography variant="overline">
            <FormattedMessage
              id="mobile.issues.issue_details.actions"
              defaultMessage="Actions ({numberOfActions})"
              values={{ numberOfActions }}
            />
          </Typography>
        </Box>
        {numberOfActions !== 0 && numberOfActions !== null && (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List className="actions">
              {workRequest && (
                <ListItem>
                  <Box display="flex" flexDirection="column" className="action">
                    <Typography variant="body1" className="header">
                      WR-{workRequest.WorkRequestId}
                    </Typography>
                    <Typography variant="caption" className="status-due">
                      {workRequests}
                    </Typography>
                    <Typography variant="body1" className="content">
                      {workRequest.Summary}
                    </Typography>
                  </Box>
                </ListItem>
              )}
              {workOrders.map((a) => {
                const workOrderDueDate = (
                  <DateString value={a.DueDate} variant="caption"></DateString>
                );

                const workOrderTime = a.DueDate && (
                  <FormattedTime value={a.DueDate}></FormattedTime>
                );

                const workOrders = a.DueDate ? (
                  <FormattedMessage
                    id="mobile.issues.issue_details.work_order_date"
                    defaultMessage="{status}; Due: {date}, {time}"
                    values={{
                      status: a.Status,
                      date: workOrderDueDate,
                      time: workOrderTime
                    }}
                  />
                ) : (
                  <FormattedMessage
                    id="mobile.issues.issue_details.work_order_no_date"
                    defaultMessage="{status}; Due: {date}"
                    values={{
                      status: a.Status,
                      date: workOrderDueDate
                    }}
                  />
                );

                return (
                  <ListItem key={a.Id}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      className="action"
                    >
                      <Typography variant="body1" className="header">
                        WO-{a.WorkOrderIdentifier.WorkOrderDistrictCode}-
                        {a.WorkOrderIdentifier.WorkOrderNumber}
                      </Typography>
                      <Typography variant="caption" className="status-due">
                        {workOrders}
                      </Typography>
                      <Typography variant="body1" className="content">
                        {a.Summary}
                      </Typography>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        )}
      </Box>
    </ListItem>
  );
};

const styledIssueAction = styled(IssueActions)`
  ul {
    margin-left: ${spacingXLarge};
    margin-right: ${spacingXLarge};

    li {
      border-top: solid 1px ${colorGray20};
      border-bottom: none;
    }
  }

  .icon {
    transition: transform 0.2s ease-in-out;
  }

  .down {
    transform: rotate(0deg);
    margin-right: ${spacingLarge};
  }

  .header {
    font-weight: bold;
  }

  .right {
    transform: rotate(-90deg);
    margin-right: ${spacingLarge};
  }

  .comment-icon {
    margin-right: ${spacingMedium};
  }

  .actions {
    padding-right: ${spacingLarge};

    .action {
      padding-bottom: ${spacingSmall};
      padding-top: ${spacingSmall};

      .content {
        margin-top: ${spacingMedium};
      }
    }
  }
`;

export default styledIssueAction;
