// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { some } from "lodash";
import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import styled from "styled-components";

import { Statuses } from "@apm/endpoint";
import { Box } from "@material-ui/core";

import IssueDetailsAppBar from "./IssueDetailsAppBar";
import IssueOverview from "./IssueOverview";
import IssueAdditionalInfo from "./IssueAdditionalInfo";
import getIssueDetailsSelector from "../selectors/getIssueDetailsSelector";
import Layout from "core/components/Layout";
import useAppBar from "core/hooks/useAppBar";
import Progress from "common/components/Progress";
import DataNotFoundMessage from "common/components/messages/DataNotFoundMessage";
import ApplicationErrorMessage from "common/components/messages/ApplicationErrorMessage";
import {
  loadIssueDetails,
  removeIssueDetails
} from "../actions/IssueDetailsActions";

interface IIssueDetailsProps {
  className?: string;
}

const IssueDetails = ({ className }: IIssueDetailsProps) => {
  const { setAppBarContent } = useAppBar();
  const { issueId } = useParams<{ issueId: string }>();
  const issueDetailsSelector = useSelector(getIssueDetailsSelector);
  const issueDetails = useMemo(
    () => issueDetailsSelector(issueId),
    [issueDetailsSelector, issueId]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setAppBarContent(() => (
      <IssueDetailsAppBar
        issueName={issueDetails?.data?.HeaderText}
        date={issueDetails?.data?.CreateDate}
      />
    ));
  }, [issueDetails, setAppBarContent]);

  useEffect(() => {
    if (issueId) {
      dispatch(loadIssueDetails(issueId));
    }
    return () => {
      if (issueId) {
        dispatch(removeIssueDetails(issueId));
      }
    };
  }, [dispatch, issueId]);

  return (
    <Layout className={className}>
      {issueDetails && issueDetails.status === Statuses.Loading && <Progress />}
      {issueDetails &&
        issueDetails.status === Statuses.Succeeded &&
        issueDetails.data && (
          <Box className="issue-details-container">
            <IssueOverview issueData={issueDetails.data}></IssueOverview>
            <IssueAdditionalInfo
              issueData={issueDetails.data}
            ></IssueAdditionalInfo>
          </Box>
        )}
      {issueDetails &&
        issueDetails.status === Statuses.Succeeded &&
        !issueDetails.data && <DataNotFoundMessage />}
      {issueDetails && issueDetails.status === Statuses.NotFound && (
        <DataNotFoundMessage />
      )}
      {issueDetails &&
        some(
          [Statuses.NetworkError, Statuses.Unauthorized, Statuses.Failed],
          (x) => issueDetails.status === x
        ) && <ApplicationErrorMessage />}
    </Layout>
  );
};

const StyledIssueDetails = styled(IssueDetails)`
  .issue-details-container {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

export default StyledIssueDetails;
