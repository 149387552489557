// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback, useState } from "react";
import styled from "styled-components";

import {
  Box,
  Drawer as MaterialUiDrawer,
  DrawerProps,
  IconButton
} from "@material-ui/core";
import Icon from "common/components/Icon";

import useDrawerData from "../hooks/useDrawerData";
import useDrawer from "../../../hooks/useDrawer";
import {
  colorAppBackgroundDark,
  colorTextPrimaryDark
} from "../../../styles/ColorVariables";

interface IDrawerRightProps extends DrawerProps {
  className?: string;
}

const DrawerRight = ({
  className,
  ...materialUiDrawerProps
}: IDrawerRightProps) => {
  const [state, setState] = useState<any>();
  const { closeDrawer } = useDrawer("right");
  const { Content, Header, Footer, visible } = useDrawerData("right");

  const handleClose = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  return (
    <MaterialUiDrawer
      {...materialUiDrawerProps}
      open={visible}
      onClose={handleClose}
      anchor="right"
      className={className}
    >
      <Box className="header">
        {Header ? <Header state={state} setState={setState} /> : <Box />}
        <IconButton className="close-button" onClick={handleClose}>
          <Icon name="close" />
        </IconButton>
      </Box>
      <Box className="content">
        {Content && <Content state={state} setState={setState} />}
      </Box>
      <Box className="footer">
        {Footer && <Footer state={state} setState={setState} />}
      </Box>
    </MaterialUiDrawer>
  );
};

const StyledDrawerRight = styled(DrawerRight)`
  .MuiPaper-root {
    width: 85vw;
    background-color: ${colorAppBackgroundDark};
    color: ${colorTextPrimaryDark};
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .close-button {
      color: ${colorTextPrimaryDark};
    }
  }

  .content {
    overflow-y: auto;
  }
`;

export default StyledDrawerRight;
