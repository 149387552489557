// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsSwitch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.932 16.19l4.775-4.776L17.293 10l-4.775 4.775a2.003 2.003 0 00-2.25.932H5.829a3.001 3.001 0 100 2h4.439a2 2 0 003.664-1.518zM29 19.707a3.001 3.001 0 01-2.83-2h-4.438a2 2 0 01-3.732-1 2 2 0 013.732-1h4.439a3.001 3.001 0 112.829 4z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsSwitch;
