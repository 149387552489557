// Copyright 2016-2024 Hitachi Energy. All rights reserved.

export const fontFamily = "Open Sans, Helvetica, Arial, sans-serif;";
export const fontSizeSmall = "0.75rem"; // 12px;
export const fontSizeMedium = "0.875rem"; // 14px;
export const fontSizeNormal = "1rem"; // 16px;
export const fontSizeLarge = "1.5rem"; // 24px;
export const fontSizeXLarge = "2.5rem"; // 40px;

export const iconFontSizeXXSmall = "8px";
export const iconFontSizeXSmall = "10px";
export const iconFontSizeSmall = "12px";
export const iconFontSizeBase = "14px";
export const iconFontSizeMedium = "16px";
export const iconFontSizeLarge = "20px";
export const iconFontSizeXLarge = "24px";
export const iconFontSizeXXLarge = "28px";
export const iconFontSizeXXXLarge = "32px";

export const spacingXSmall = "2px";
export const spacingSmall = "4px";
export const spacingMedium = "6px";
export const spacingNormal = "8px";
export const spacingLarge = "12px";
export const spacingXLarge = "16px";
export const spacingXXLarge = "24px";
export const spacingXXXLarge = "48px";
export const spacingXXXXLarge = "72px";
export const spacingGridItem = "10px";
