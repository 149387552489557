// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getNugetLicensesSelector from "./getNugetLicensesSelector";

const getNugetLicensesStatusSelector = createSelector(
  getNugetLicensesSelector,
  nugetLicensesSelector => () => {
    const nugetLicenses = nugetLicensesSelector;
    return nugetLicenses && nugetLicenses.status;
  }
);

export default getNugetLicensesStatusSelector;
