// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";

import { Box, Typography } from "@material-ui/core";

import { colorPrimary } from "core/styles/ColorVariables";

interface IStatusProps {
  className?: string;
  status: string;
}

const Status = ({ className, status }: IStatusProps) => {
  const intl = useIntl();
  return (
    <Box className={className} data-qa="issue-status">
      <Typography variant="body2">
        {intl.formatMessage({
          defaultMessage: status,
          id: `mobile.asset_details.issues.statuses.${status}`
        })}
      </Typography>
    </Box>
  );
};

const StyledStatus = styled(Status)`
  color: ${colorPrimary};

  .MuiTypography-root {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export default StyledStatus;
