// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { AxiosRequestConfig } from "axios";
import { isEmpty } from "lodash";
import React, {
  useEffect,
  useCallback,
  useRef,
  ComponentType,
  CSSProperties
} from "react";
import styled from "styled-components";

import { List, ListSubheader, ListItem, Divider, Box } from "@material-ui/core";

import { spacingNormal, spacingGridItem } from "core/styles/StyleVariables";

import Row from "./Row";
import useGrid from "./hooks/useGrid";
import { Statuses } from "./models/Statuses";
import IDataClient from "./services/IDataClient";
import isLastVisible from "./utils/isLastVisible";
import Progress from "../Progress";
import DataNotFoundMessage from "../messages/DataNotFoundMessage";
import ApplicationErrorMessage from "../messages/ApplicationErrorMessage";
import IItemProps from "./models/IItemProps";

interface IGridProps {
  className?: string;
  dataOrConfig: Array<any> | AxiosRequestConfig | undefined;
  hideEmptyDataInfo?: boolean;
  hideRowItemDivider?: boolean;
  Subheader?: () => JSX.Element;
  ItemComponent: ComponentType<IItemProps<any>>;
  onTotalUpdate?: (totalRows: number) => void;
  onClick?: (data: any) => void;
  onScroll?: (e: React.UIEvent<HTMLElement>) => void;
  itemStyle?: CSSProperties | undefined;
  dataQa?: string;
}

const chunkSize = 20;
const triggerCount = 3;

const Grid = ({
  className,
  dataOrConfig,
  hideEmptyDataInfo,
  hideRowItemDivider,
  Subheader,
  ItemComponent,
  onTotalUpdate,
  onClick,
  itemStyle,
  dataQa
}: IGridProps) => {
  const listRef = useRef<HTMLDivElement>(null);
  const clientRef = useRef<IDataClient>();
  const { client, data, loadNext, status } = useGrid(
    dataOrConfig,
    chunkSize,
    onTotalUpdate
  );

  useEffect(() => {
    clientRef.current = client;

    return () => {
      if (clientRef.current) {
        clientRef.current.destroy();
        clientRef.current = undefined;
      }
    };
  }, [client]);

  const handleScroll = useCallback(() => {
    const visible = isLastVisible(listRef, triggerCount);
    if (visible) loadNext();
  }, [loadNext]);

  return (
    <Box className={className} data-qa={dataQa}>
      {status === Statuses.Loading && <Progress />}
      {status === Statuses.Failed && <ApplicationErrorMessage />}
      {!hideEmptyDataInfo && isEmpty(data) && status === Statuses.Succeeded && (
        <DataNotFoundMessage direction="column" />
      )}
      <List
        disablePadding={true}
        innerRef={listRef}
        onScroll={handleScroll}
        {...(Subheader
          ? {
              subheader: (
                <>
                  <ListSubheader>
                    <Subheader />
                  </ListSubheader>
                  <Divider />
                </>
              )
            }
          : {})}
      >
        {data &&
          data.map((item: any, index: number) => (
            <Box className="grid-item" key={`grid-item-${index}`}>
              <Row
                item={item}
                onClick={onClick}
                ItemComponent={ItemComponent}
                style={itemStyle}
              />
              {!hideRowItemDivider && <Divider />}
            </Box>
          ))}
        {status === Statuses.Updating && (
          <ListItem className="grid-item update-indicator">
            <Progress />
          </ListItem>
        )}
      </List>
    </Box>
  );
};

const StyledGrid = styled(Grid)`
  height: 100%;
  overflow-y: hidden;

  ul {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    word-break: break-word;

    .grid-item {
      > li {
        padding-top: ${spacingGridItem};
        padding-bottom: ${spacingGridItem};
      }
    }
  }

  .update-indicator {
    margin-top: ${spacingNormal};
    margin-bottom: ${spacingNormal};
  }
`;

export default StyledGrid;
