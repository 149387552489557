// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { Typography } from "@material-ui/core";
import Icon from "common/components/Icon";

import getEnvironmentSelector from "core/selectors/getEnvironmentSelector";
import getUserSelector from "core/selectors/getUserSelector";

interface ISupportEmailProps {
  className?: string;
}

const Support = ({ className }: ISupportEmailProps) => {
  const environment = useSelector(getEnvironmentSelector);
  const user = useSelector(getUserSelector);
  const adSupportContactUrl = environment.data?.AdSupportContactUrl;
  const userEmail = user.data?.EmailAddress;

  const handleClick = useCallback(() => {
    window.open(adSupportContactUrl, "_blank");
  }, [adSupportContactUrl]);

  return (
    <div
      className={className}
      data-qa="support"
      onClick={adSupportContactUrl && userEmail ? handleClick : undefined}
    >
      {adSupportContactUrl && userEmail ? (
        <>
          <Icon name="insert_link" />
          <Typography variant="body1">
            <FormattedMessage
              defaultMessage="Contact with us"
              id="mobile.sidepanel.support.contact_with_us"
            />
          </Typography>
        </>
      ) : (
        <Typography variant="body1">
          <i>
            <FormattedMessage
              id="mobile.sidepanel.support.help"
              defaultMessage="Please configure your profile or ask for help from your administrator."
            />
          </i>
        </Typography>
      )}
    </div>
  );
};

export default styled(Support)`
  display: flex;
  align-items: center;

  .icon {
    margin-right: 1ch;
  }
`;
