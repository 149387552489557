// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { useSelector } from "react-redux";

import { Statuses } from "@apm/endpoint";

import { AppStatuses } from "../models/AppStatuses";
import getTranslationsSelector from "../selectors/getTranslationsSelector";
import getUserSelector from "../selectors/getUserSelector";
import getVersionSelector from "../selectors/getVersionSelector";
import getTenantsSelector from "../selectors/getTenantsSelector";
import getEnvironmentSelector from "../selectors/getEnvironmentSelector";

const useApp = () => {
  const translations = useSelector(getTranslationsSelector);
  const user = useSelector(getUserSelector);
  const version = useSelector(getVersionSelector);
  const tenants = useSelector(getTenantsSelector);
  const environment = useSelector(getEnvironmentSelector);

  const isAll = (status: Statuses) =>
    translations &&
    translations.status === status &&
    user &&
    user.status === status &&
    version &&
    version.status === status &&
    tenants &&
    tenants.status === status &&
    environment &&
    environment.status === status;

  const isAny = (status: Statuses) =>
    (translations && translations.status === status) ||
    (user && user.status === status) ||
    (version && version.status === status) ||
    (tenants && tenants.status === status) ||
    (environment && environment.status === status);

  let appStatus = AppStatuses.Loading;

  if (isAny(Statuses.Unauthorized)) appStatus = AppStatuses.Authenticating;
  else if (isAny(Statuses.Failed)) appStatus = AppStatuses.Error;
  else if (isAny(Statuses.Forbidden)) appStatus = AppStatuses.Forbidden;
  else if (isAny(Statuses.Loading)) appStatus = AppStatuses.Loading;
  else if (isAll(Statuses.Succeeded)) appStatus = AppStatuses.Started;

  return { appStatus };
};

export default useApp;
