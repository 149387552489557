// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getNpmLicensesSelector from "./getNpmLicensesSelector";

const getNpmLicensesStatusSelector = createSelector(
  getNpmLicensesSelector,
  npmLicensesSelector => () => {
    const npmLicenses = npmLicensesSelector;
    return npmLicenses && npmLicenses.status;
  }
);

export default getNpmLicensesStatusSelector;
