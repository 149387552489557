// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { IntlShape } from "react-intl";
import { createSelector } from "reselect";

import getCommonLicensesSelector from "./getCommonLicensesSelector";
import getNpmLicensesSelector from "./getNpmLicensesSelector";
import getNugetLicensesSelector from "./getNugetLicensesSelector";
import getMavenLicensesSelector from "./getMavenLicensesSelector";
import ILicenseData from "../models/ILicenseData";
import convertNpmLicensesToData from "../utils/convertNpmLicensesToData";
import convertCommonLicensesToData from "../utils/convertCommonLicensesToData";
import convertNugetLicensesToData from "../utils/convertNugetLicensesToData";
import convertMavenLicensesToData from "../utils/convertMavenLicensesToData";

const getDataForLicensesSelector = createSelector(
  getCommonLicensesSelector,
  getNpmLicensesSelector,
  getNugetLicensesSelector,
  getMavenLicensesSelector,
  (
    commonLicensesSelector,
    npmLicensesSelector,
    nugetLicensesSelector,
    mavenLicensesSelector
  ) => (intl: IntlShape) => {
    const common = commonLicensesSelector && commonLicensesSelector.data;
    const npm = npmLicensesSelector && npmLicensesSelector.data;
    const nuget = nugetLicensesSelector && nugetLicensesSelector.data;
    const maven = mavenLicensesSelector && mavenLicensesSelector.data;
    const licensesData: ILicenseData[] = [];

    if (common) licensesData.push(...convertCommonLicensesToData(common));
    if (npm) licensesData.push(...convertNpmLicensesToData(npm));
    if (nuget) licensesData.push(...convertNugetLicensesToData(nuget, intl));
    if (maven) licensesData.push(...convertMavenLicensesToData(maven, intl));

    return licensesData;
  }
);

export default getDataForLicensesSelector;
