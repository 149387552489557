// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import { Statuses } from "@apm/endpoint";

import getAssetDetailsSelector from "./getAssetDetailsSelector";
import getAssetDetailsStatusSelector from "./getAssetDetailsStatusSelector";

const getAssetDetailsControlFlagsSelector = createSelector(
  getAssetDetailsStatusSelector,
  getAssetDetailsSelector,
    (assetDetailsStatusSelector, assetDetailsSelector) => (
        assetId: string | undefined
    ) => {
      const assetDetailsStatus = assetDetailsStatusSelector(assetId);
      const assetDetails = assetDetailsSelector(assetId);
      return {
        hasStatusLoading: assetDetailsStatus === Statuses.Loading,
        hasStatusSucceeded: assetDetailsStatus === Statuses.Succeeded,
        hasStatusFailed: assetDetailsStatus === Statuses.Failed,
        hasStatusNotFound: assetDetailsStatus === Statuses.NotFound,
        hasNameplate:
          !!assetDetails && !!assetDetails.data && !!assetDetails.data.Nameplate
      };
    }
);

export default getAssetDetailsControlFlagsSelector;
