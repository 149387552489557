// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";

import IDataClient from "./IDataClient";
import IRequest from "../models/IRequest";
import IResponse from "../models/IResponse";
import { Statuses } from "../models/Statuses";
import SearchParams from "core/models/SearchParams";
import endpoint from "@apm/endpoint";

export default class RemoteClient implements IDataClient {
  private config: AxiosRequestConfig;
  private total: number | undefined = undefined;
  private destroyed: boolean;

  constructor(config: AxiosRequestConfig) {
    this.config = config;
    this.destroyed = false;
  }

  loadFirst(
    chunkSize: number,
    setData: (data: any[]) => void,
    setTotal: (total: number) => void,
    setStatus: (status: Statuses) => void
  ): Promise<unknown> {
    this.destroyed = false;
    setStatus(Statuses.Loading);
    const config = RemoteClient.applyParams(this.config, 0, chunkSize);
    return endpoint(config)
      .then((response: AxiosResponse<IResponse<any>>) => {
        this.total = response.data.Total;

        if (!this.destroyed) {
          setData(response.data.RowData);
          setTotal(response.data.Total);
          setStatus(Statuses.Succeeded);
        }
      })
      .catch((error: AxiosError) => {
        const status =
          error.response && error.response.status === 404
            ? Statuses.Succeeded
            : error.response && error.response.status === 401
            ? Statuses.Unauthorized
            : Statuses.Failed;

        if (!this.destroyed) setStatus(status);
      });
  }

  loadNext(
    currentData: any[],
    chunkSize: number,
    setData: (data: any[]) => void,
    setTotal: (total: number) => void,
    setStatus: (status: Statuses) => void
  ): Promise<unknown> {
    this.destroyed = false;
    setStatus(Statuses.Updating);
    const config = RemoteClient.applyParams(
      this.config,
      currentData.length,
      chunkSize
    );
    return endpoint(config)
      .then((response: AxiosResponse<IResponse<any>>) => {
        this.total = response.data.Total;

        if (!this.destroyed) {
          setData(currentData.concat(response.data.RowData));
          setTotal(response.data.Total);
          setStatus(Statuses.Succeeded);
        }
      })
      .catch((error: AxiosError) => {
        const status =
          error.response && error.response.status === 401
            ? Statuses.Unauthorized
            : Statuses.Failed;

        if (!this.destroyed) setStatus(status);
      });
  }

  destroy() {
    this.destroyed = true;
  }

  canLoadNext(currentCount: number, status: Statuses | undefined): boolean {
    if (!this.total) return false;
    if (currentCount >= this.total) return false;
    if (status !== Statuses.Succeeded) return false;
    return true;
  }

  private static applyParams(
    config: AxiosRequestConfig,
    start: number,
    size: number
  ) {
    const request: IRequest = {
      Start: start,
      Size: size,
    };

    const configData = { ...config.data, ...request };
    config = { ...config, data: configData };

    if (config.method && config.method.toLowerCase() === "get")
      config = RemoteClient.applyParamsToUrl(config);

    return config;
  }

  private static applyParamsToUrl(config: AxiosRequestConfig) {
    const searchParams = new SearchParams(config.data);
    const hasQueryParams = !!(config.url && config.url.indexOf("?") >= 0);
    const delimiter = hasQueryParams ? "&" : "?";
    config.url = `${config.url}${delimiter}${searchParams.toString()}`;
    return config;
  }
}
