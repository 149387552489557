// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import endpoint, { getUrl } from "@apm/endpoint";

import { loadDataAction } from "core/actions/DataActions";

const versionUrl = "/version.json";

export const dataKey = "version";

export const loadVersionAction = () => {
  const url = getUrl(versionUrl);
  return loadDataAction(dataKey, endpoint.get, url);
};
