// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { createSelector } from "reselect";

import getCommonLicensesSelector from "./getCommonLicensesSelector";

const getCommonLicensesStatusSelector = createSelector(
  getCommonLicensesSelector,
  commonLicensesSelector => () => {
    const commonLicenses = commonLicensesSelector;
    return commonLicenses && commonLicenses.status;
  }
);

export default getCommonLicensesStatusSelector;
