// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";
import { useMemo } from "react";
import styled from "styled-components";

import { Box } from "@material-ui/core";

import ChangeDirectionType from "../../models/ChangeDirectionType";
import Icon from "common/components/Icon";

import { spacingLarge } from "core/styles/StyleVariables";
import { colorPrimary } from "core/styles/ColorVariables";

interface IChangeDirectionIndicatorProps {
  className?: string;
  changeDirection: ChangeDirectionType;
}

const ChangeDirectionIndicator = ({
  className,
  changeDirection
}: IChangeDirectionIndicatorProps) => {
  const iconName = useMemo(() => {
    if (changeDirection === "Up") {
      return "north";
    } else if (changeDirection === "Down") {
      return "south";
    } else if (changeDirection === "None") {
      return "east";
    }

    return null;
  }, [changeDirection]);

  return (
    <Box className={`${className} change-direction`}>
      {iconName && <Icon name={iconName} size="sm" variant="outlined" />}
    </Box>
  );
};

const StyledChangeIndicator = styled(ChangeDirectionIndicator)`
  min-width: 16px;
  padding-left: ${spacingLarge};

  .icon {
    color: ${colorPrimary};
  }
`;

export default StyledChangeIndicator;
