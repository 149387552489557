// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React, { SVGProps } from "react";

const SvgAssetsBattery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 7h2v18h-2v-8H5.83a3.001 3.001 0 110-2H13V7zM26.17 17a3.001 3.001 0 100-2H19v-3h-2v8h2v-3h7.17z"
      fill="#000"
    />
  </svg>
);

export default SvgAssetsBattery;
