// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import { Location, LocationDescriptorObject } from "history";
import { ChangeEvent, useMemo, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";

import SearchParams from "core/models/SearchParams";
import Icon from "common/components/Icon";
import useNavigate from "core/hooks/useNavigate";

const assetDetailsNavigationHeight = "56px";

interface IAssetDetailsNavigationProps {
  className?: string;
}

const AssetDetailsNavigation = ({
  className
}: IAssetDetailsNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const locations = useMemo<LocationDescriptorObject[]>(() => {
    const assetId = new SearchParams(location.search).get("assetId");
    const searchParams = new SearchParams();
    if (assetId) searchParams.set("assetId", assetId);

    return [
      {
        pathname: "/locations/assets/details",
        search: searchParams.toString() || undefined
      },
      {
        pathname: "/locations/assets/details/parameters",
        search: searchParams.toString() || undefined
      },
      {
        pathname: "/locations/assets/details/action-plan",
        search: searchParams.toString() || undefined
      }
    ];
  }, [location]);

  const mapValueToLocation = useCallback(
    (value: number) => locations[value],
    [locations]
  );

  const mapLocationToValue = useCallback(
    (location: Location) =>
      locations.findIndex((v) => v.pathname === location.pathname),
    [locations]
  );

  const [value, setValue] = useState(mapLocationToValue(location));

  const handleNavigationChange = useCallback(
    (e: ChangeEvent<{}>, value: number) => {
      setValue(value);
      navigate(mapValueToLocation(value));
    },
    [navigate, mapValueToLocation]
  );

  return (
    <BottomNavigation
      className={className}
      onChange={handleNavigationChange}
      value={value}
      showLabels
    >
      <BottomNavigationAction
        data-qa="bottom-navigation-overview"
        label={
          <FormattedMessage
            defaultMessage="Overview"
            id="mobile.asset_details.navigation.overview"
          />
        }
        icon={<Icon name="analytics" size="md" variant="outlined" />}
      />
      <BottomNavigationAction
        data-qa="bottom-navigation-parameters"
        label={
          <FormattedMessage
            defaultMessage="Parameters"
            id="mobile.asset_details.navigation.parameters"
          />
        }
        icon={<Icon name="stacked_line_chart" size="md" />}
      />
      <BottomNavigationAction
        data-qa="bottom-navigation-action-plan"
        label={
          <FormattedMessage
            defaultMessage="Action Plan"
            id="mobile.asset_details.navigation.action_plan"
          />
        }
        icon={<Icon name="construction" size="md" />}
      />
    </BottomNavigation>
  );
};

const StyledAssetDetailsNavigation = styled(AssetDetailsNavigation)`
  min-height: ${assetDetailsNavigationHeight};
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2),
    0px 2px 4px 2px rgba(0, 0, 0, 0.14), 0px 4px 4px 2px rgba(0, 0, 0, 0.12);
  min-height: 56px;
  z-index: 100;
`;

export default StyledAssetDetailsNavigation;
