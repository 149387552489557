// Copyright 2016-2024 Hitachi Energy. All rights reserved.

import React from "react";

import DrawerRight from "./DrawerRight";
import DrawerBottom from "./DrawerBottom";

const Drawers = () => {
  return (
    <>
      <DrawerRight />
      <DrawerBottom />
    </>
  );
};

export default Drawers;
